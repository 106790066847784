import axios from 'axios';
// import { message } from 'antd';
import { showErr } from './tools'
import { Toast } from 'antd-mobile';
let baseUrl = ''
// if (process.env.NODE_ENV === 'development') {    
//   baseUrl = base.dev
// } else{
//   baseUrl = base.pd
// }
baseUrl = 'https://photostudio.91pitu.com'
var instance = axios.create({ timeout: 1000 * 20 });
instance.interceptors.request.use(
  config => {
    return config;
  },
  error => Promise.reject(error)
)
instance.interceptors.response.use(
  res => Promise.resolve(res),
  err => {
    return Promise.reject(err);
  }
);

var ajaxSources = 0;
export default function c2s(ajaxOptions, customOptions = {}) {
  let onSuccess = ajaxOptions.onSuccess || (() => { });
  let onError = ajaxOptions.onError || (() => { });
  customOptions = convertCustom(customOptions)
  customOptions.autoApplyUrlPrefix && (ajaxOptions.url = baseUrl + ajaxOptions.url)
  ajaxOptions.headers = ajaxOptions.headers || {};
  if (ajaxOptions.method === 'post' || ajaxOptions.method === 'POST' || ajaxOptions.method === 'patch' || ajaxOptions.method === 'PATCH') {
    ajaxOptions.data = ajaxOptions.params
    ajaxOptions.params = {}
  }
  showMask(customOptions.mask)
  return new Promise((resolve, reject) => {
    instance(ajaxOptions).then((res) => {
      if (res.data.code && res.data.code !== 200 && res.data.code !== 201) {
        cleanMask(customOptions.mask)
        errorTip(res, customOptions.silentError)
        onError(res)
        reject(res)
        return
      }
      cleanMask(customOptions.mask)
      onSuccess(res);
      resolve(res)
    }).catch((err) => {
      cleanMask(customOptions.mask)
      errorTip(err, customOptions.silentError)
      onError(err)
      reject(err)
    });
  });
}


const errorTip = (err, silentError) => {
  console.log(err)
  if (!silentError) {
    return
  }
  if (err.data) {
    showErr(err.data.result, '提示')
    return
  }
  if (!err.response) {
    showErr('网络错误,请稍后重试', '提示')
    return
  }
  if (err.response.status !== 401) {
    // err.response.data.message?message.error(err.response.data.message):message.error('未知错误,请联系管理员')
    err.response.data.result ? showErr(err.response.data.result, '提示') : showErr('服务器繁忙,请稍后重试', '提示')
  }
};


let convertCustom = (customOptions) => {
  let tmp = customOptions
  customOptions = {
    mask: true,
    autoApplyUrlPrefix: true,
    silentError: true
  }
  Object.keys(tmp).forEach((element) => {
    customOptions[element] = tmp[element]
  });
  return customOptions
}
let showMask = (mask) => {
  if (mask) {
    ajaxSources++
    ajaxSources === 1 && (
      Toast.loading('加载中...', 10, () => {
        console.log('Load complete !!!');
      })
    )
  }
}
let cleanMask = (mask) => {
  if (mask) {
    ajaxSources--
    ajaxSources === 0 && (
      // XXX:异步问题？
      // setTimeout(() => {
      Toast.hide()
      // }, 100)
    )
  }
}