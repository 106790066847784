/**
 * 函数柯里化
 * @param {Function} fn 需柯里化的原函数
 */
export const curry = fn => {
    const { length } = fn
    const curried = (...args) => {
        return (args.length >= length
            ? fn(...args)
            : (...args2) => curried(...args, ...args2))
        // .concat(args2)
    }
    return curried
}

export const isNA = reg => reg.test(window.navigator.userAgent);
export const isNotNA = reg => !(reg.test(window.navigator.userAgent));