import React from "react";
import "./index.scss";
import { setTitle } from '@/utils/tools'
export default function strategy() {
    setTitle("使用攻略")
    return (
        <div>
            <img src={[require("@/assets/tutorial_top_image@2x.png")]} alt="" />
            <img src={[require("@/assets/tutorial_share_step@2x.png")]} alt="" />
            <img src={[require("@/assets/tutorial_money_one@2x.png")]} alt="" />
            <img src={[require("@/assets/tutorial_money_two@2x.png")]} alt="" />
        </div>
    )
}