import React, { useEffect, useRef, useState, useReducer } from "react";
import "./pullToRefresh.css";
const preventDefaultFn = event => { event.stopPropagation() }
const loadingTextReducer = (state, action) => action;
export default function ({ children, style, refreshing, onRefresh = _ => { }, distanceToRefresh, page_size }) {
    const scrollRef = useRef();
    const [styleState, setStyle] = useState(null);
    const [startY, setStartY] = useState();
    const [distanceY, setDistanceY] = useState(0);
    const [isRefreshing, setIsRefreshing] = useState(refreshing)
    const [loadingText, dispatchLoadingText] = useReducer(loadingTextReducer)
    const handleTouchstart = (event, el) => {
        setIsRefreshing(true)
        if (el.scrollHeight - el.clientHeight - el.scrollTop - 25 <= 0) {
            setStartY(event.changedTouches[0].pageY);
        }
    }
    const handleTouchmove = (event, el, startY, refreshBol, distanceToRefresh) => {
        if (el.scrollHeight - el.clientHeight - el.scrollTop - 25 <= 0) {
            const endY = event.changedTouches[0].pageY;
            const distance = startY !== undefined ? (endY - startY < -distanceToRefresh && - distanceToRefresh) : 0;
            distance < 0 && setStyle(Object.assign({}, {
                transform: `translate3d(0px, ${distance}px, 0px)`
            }));
            setDistanceY(endY - startY);
            dispatchLoadingText(refreshBol ? "松开立即刷新" : "上拉可以刷新")
        }
    }
    const handleTouchend = (event, el, fn, refreshBol, page_size) => {
        if ((el.scrollHeight - el.clientHeight) - el.scrollTop - 25 <= 0) {
            if (refreshBol) {
                fn().then(resultList => {
                    resultList.length !== page_size
                        ? dispatchLoadingText("没有更多数据了")
                        : dispatchLoadingText("上拉可以刷新");
                    setTimeout(res => {
                        setStyle(Object.assign({}, {
                            transform: `translate3d(0px, 0px, 0px)`,
                            transition: "transform 0.25s"
                        }));
                        setIsRefreshing(false)
                    }, 1000)
                })
            } else {
                setStyle(Object.assign({}, {
                    transform: `translate3d(0px, 0px, 0px)`,
                    transition: "transform 0.25s"
                }));
                setTimeout(_ => {
                    setIsRefreshing(false)
                }, 250)
            }
        }
    }
    useEffect(() => {
        const el = scrollRef.current;
        const eventTouchStart = _ => {
            _.stopPropagation()
            handleTouchstart(_, el)
        }
        const eventTouchmove = _ => {
            _.stopPropagation()
            handleTouchmove(_, el, startY, (-distanceY >= distanceToRefresh), distanceToRefresh)
        }
        const eventTouchend = _ => {
            handleTouchend(_, el, onRefresh, (-distanceY >= distanceToRefresh), page_size)
        }
        el.addEventListener('touchstart', eventTouchStart, { passive: false });
        el.addEventListener('touchmove', eventTouchmove, { passive: false });
        el.addEventListener('touchend', eventTouchend, { passive: false });
        document.body.addEventListener("touchmove", preventDefaultFn, { passive: false })
        return () => {
            el.removeEventListener('touchstart', eventTouchStart, { passive: false });
            el.removeEventListener('touchmove', eventTouchmove, { passive: false });
            el.removeEventListener('touchend', eventTouchend, { passive: false });
            document.body.removeEventListener("touchmove", preventDefaultFn, { passive: false });
        };
    }, [onRefresh, startY, distanceY, distanceToRefresh, page_size])
    return (
        <div ref={scrollRef} {...{ style }} className="refresh-wrap">
            <div className="refresh-concent-wrap" {...{ style: styleState }}>
                <div className="list-body" {...{ children }} />
                {isRefreshing && <div className="loading">{loadingText}</div>}
            </div>
        </div >
    )
}