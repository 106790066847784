import React, { useReducer, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {getPhoneDao} from "@/utils/dao.js";
import { setTitle } from '@/utils/tools'
import incomeService from '@/service/incomeService'
import Process from "./components/process"
import Mission from "./components/mission";
import RuleExplain from "./components/ruleExplain"
import GetBoundMask from "./components/getBoundMask"
import './index.scss'
const defaultReducer = (state, action) => {
    return Object.assign({}, state, action)
}
const isMissionFinshArrReducer = (state, action) => {
    // XXX:待优化梳理 关闭
    if (action.closeIdx !== undefined) {
        state[action.closeIdx].isFinsh = !state[action.closeIdx].isFinsh;
        window.localStorage.setItem("lq-pocket-closeIdx", action.closeIdx);
        return [...state]
    }
    const [taskList, stateList] = action;
    return stateList.map((item, idx) => {
        const isFinsh = window.localStorage.getItem("lq-pocket-closeIdx")
            ? item.task_state === 1 && idx > +window.localStorage.getItem("lq-pocket-closeIdx")
            : item.task_state === 1;
        return {
            isFinsh,
            boundNum: taskList[idx].bonus
        }
    })
}
const taskListReducer = (state, action) => {
    const [cosntList, injectObj] = action;
    // XXX: 待优化
    return cosntList.map((item, idx) => {
        item[Object.entries(injectObj)[0][0]] = Object.entries(injectObj)[0][1][idx]
        return item
    })
}
const onGoingIdxReducer = (state, action) => {
    const onGoing = action.findIndex(item => item === false);
    return onGoing !== -1 ? onGoing - 1 : action.length
}
export default withRouter(function Bounty(params) {
    const [onGoingIdx, dispatchOnGoingIdx] = useReducer(onGoingIdxReducer)
    const [constData, dispatchConstData] = useReducer(defaultReducer, {});
    const [taskList, dispatchTaskList] = useReducer(taskListReducer, [])
    const [isMissionFinshArr, dispatchIsMissionFinsh] = useReducer(isMissionFinshArrReducer, []);

    useEffect(() => {
        incomeService.getBonusDetail({
            params: { phone: getPhoneDao() },
            onSuccess: ({ data: { result: { const: constData, state } } }) => {
                dispatchConstData(constData);
                dispatchTaskList([constData.task, { state }]);
                dispatchOnGoingIdx(state);
                dispatchIsMissionFinsh([constData.task, state])
            }
        })
    }, [])
    setTitle("奖励金")
    return (
        <div className="bounty" >
            <img style={{ width: "100%", position: "absolute", zIndex: "0" }} src={[require("@/assets/award_image_bg@2x.png")]} alt="" />
            <Process {...taskList[onGoingIdx]} />
            <div className="bounty-shop-info">
                <div className="item">
                    <img src={[require("@/assets/award_icon_date@2x.png")]} alt="" />
                    开店天数<span>{constData.open_days}</span>
                </div>
                <div className="item">
                    <img src={[require("@/assets/award_icon_order@2x.png")]} alt="" />
                    累计开单<span>{constData.order_total}</span>
                </div>
            </div>
            <Mission {...{ taskList }} />
            <RuleExplain {...{ taskList }} />
            <GetBoundMask {...{
                isMissionFinshArr,
                close: _ => { dispatchIsMissionFinsh({ closeIdx: isMissionFinshArr.findIndex((item) => (item.isFinsh === true)) }) }
            }} />

        </div>
    )
})