import React from "react";
import "./index.scss"
export default function step() {
    return (
        <div className="step" style={{ width: '347px', height: "70px", padding: "13px 28px 11px", background: '#F7F8F9', fontSize: "12px", justifyContent: "space-between" }}>
            <div>
                <img src={[require("@/assets/index_step_one@2x.png")]} style={{ width: "24px", margin: "0 auto 6px" }} className="icon" alt="" />
                1.分享海报
            </div>
            <img src={[require("@/assets/step_icon_arrows.png")]} style={{ width: "12px", height: "13px", marginBottom: "3px" }} className="step-arrows" alt="" />
            <div>
                <img src={[require("@/assets/index_step_two@2x.png")]} style={{ width: "24px", margin: "0 auto 6px" }} className="icon" alt="" />
                2.好友成功制作证件照
            </div>
            <img src={[require("@/assets/step_icon_arrows.png")]} style={{ width: "12px", height: "13px", marginBottom: "3px" }} className="step-arrows" alt="" />
            <div>
                <img src={[require("@/assets/index_step_three@2x.png")]} style={{ width: "24px", margin: "0 auto 6px" }} className="icon" alt="" />
                3.奖励到账
            </div>
        </div>
    )
}