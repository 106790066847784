import React from 'react';
import './ristWarning.scss'
export default function RistWarning({ limit_days = 0, limit_num = 0, close }) {
    return (
        <div className="ristWarning mask">
            <div className="bounceIn" style={{ position: "relative" }}>
                <img className="ristWarning-bgImg" src={[require("@/assets/pop_danger_bg@2x.png")]} alt="" />
                <img onClick={close} className="ristWarning-close" src={[require("@/assets/pop_icon_cancle@2x.png")]} alt="" />
                <div className="ristWarning-conent">
                    <div className="ristWarning-ENtitle">RISK WARNING</div>
                    <div className="ristWarning-CNtitle">风险提示</div>
                    <div className="ristWarning-line"></div>
                    <div className="ristWarning-dec">
                        您开店{limit_days}日内的成交订单低于<span>{limit_num}单</span>，无法达到奖励金提现门槛~  有疑问可联系客服~
                    </div>
                    <a href="tel:18020506879" className="ristWarning-phoneBtn">
                        联系客服
                    </a>
                </div>
            </div>
        </div>
    )
}