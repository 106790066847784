import React, { useState, useEffect, useReducer } from 'react'
import { useHistory } from "react-router-dom";
import {getPhoneDao} from "@/utils/dao.js";
import './index.scss'
import { setTitle } from '@/utils/tools'
import Money from './components/money'
import Record from './components/record'
import NewSchemeToast from './components/newSchemeToast'
import incomeService from '@/service/incomeService'
import store from "@/store/store";
import action from "@/store/action";
import RistWarning from './components/ristWarning'
const defaultReducer = (state, action) => {
  return Object.assign({}, state, action)
}
export default function Income(params) {
  const [info, dispatchInfo] = useReducer(defaultReducer,
    {
      dayIncome: '',
      totalIncome: '',
      withdraw: '',
      recordArr: [],
    }
  ), [ristWarningInfo, dispatchRistWarning] = useReducer(defaultReducer, {
    risk_code: false,
    // XXX: 可优化
    close: _ => {
      dispatchRistWarning({ risk_code: false })
    }
  });
  const history = useHistory()
  const goBounty = () => {
    history.push("/bounty")
  }
  const [showNewSchemeToast, setShowNewSchemeToast] = useState(!store.getState().isShowedNewSchemeToast);
  function getIncomeInfo() {
    incomeService.getInitialization({
      params: { phone: getPhoneDao() },
      onSuccess: ({ data }) => {
        let { result } = data;
        result.risk_code = !!result.risk_code;
        dispatchRistWarning(result)
      }
    })
    incomeService.getInfo({
      params: { phone: getPhoneDao() },
      onSuccess: ({ data: { result } }) => {
        dispatchInfo({
          commission: result.commission.toFixed(2),
          totalIncome: result.total_income.toFixed(2),
          withdraw: result.withdraw.toFixed(2),
          bonus: result.bonus.toFixed(2)
        })
      }
    })

  }
  setTitle('我的收入')
  useEffect(() => {
    getIncomeInfo();
  }, [params.location.state])
  let { ...moneyData } = info;
  return (
    <div className="income">
      <Money  {...moneyData} />
      <Record />
      {showNewSchemeToast && <NewSchemeToast goBounty={goBounty} closeToast={_ => {
        setShowNewSchemeToast(false);
        store.dispatch(action.setIsShowedNewSchemeToast(true))
      }} />}
      {ristWarningInfo.risk_code && <RistWarning {...ristWarningInfo} />}
    </div>
  )
}