import Cookies from "js-cookie";
function setStorage(key,val,expire){
    if(expire){
        //设置过期时间
        let d = new Date();
        localStorage.setItem(`${key}_expire`,JSON.stringify(d.setDate(d.getDate()+expire)))
    }
    localStorage.setItem(key,JSON.stringify(val));
}
function getStorage(key){
    let expire = JSON.parse(localStorage.getItem(`${key}_expire`)||'""');
    let canbol = true;
    if(expire){
        let st = new Date().getTime();
        canbol = expire>st;
    }
    if(canbol){
        return JSON.parse(localStorage.getItem(key)||'""')
    }else{
        //过期
        removeStorage(key)
        return '';
    }
}
function removeStorage(key){
    localStorage.removeItem(`${key}_expire`);
    localStorage.removeItem(key);
}

let phoneDao = "lq-pocket-phone"
function setPhoneDao(expire){
    Cookies.get('token')
    setStorage(phoneDao,Cookies.get('token'),expire)
}
function removePhoneDao(){
    removeStorage(phoneDao);
    Cookies.remove('token');
}
function getPhoneDao(){
    //获取phone顺便判断下当前cookie有没有值

    let p = getStorage(phoneDao);
    if(p){
        let c = Cookies.get('token');
        if(!c){
            Cookies.set('token',p);
        }
    }
    return p
}

export {
    setPhoneDao,
    getPhoneDao,
    removePhoneDao
}