import React from 'react'
import './index.scss'
// import {setPhone} from '@/utils/tools'
import { setTitle } from '@/utils/tools'
import loginService from '@/service/loginService'
import { Toast } from 'antd-mobile';
import {setPhoneDao} from "@/utils/dao.js";
const qs = require('query-string');
let sInterval;
export default class Login extends React.Component {
  login() {
    // showErr('登录失败，请重新登录～')
    let self = this;
    if (!/^1[0-9]{10}$/.test(this.state.phone) || !this.state.code) {
      Toast.fail('请输入手机号和验证码', 2)
      return
    }
    if (this.state.type === 'regist') {
      //注册
      loginService.regist({
        params: {
          name: this.state.name,
          phone: this.state.phone,
          captcha: this.state.code,
          app_id: this.state.appid,
        },
        onSuccess: ({ data }) => {
          setPhoneDao(29);
          if (this.state.uniqueId) {
            window.localStorage.setItem('lq-pocket-uniqueId', this.state.uniqueId)
          }
          Toast.success('注册成功', 1, _ => {
            setTimeout(_ => {
              self.props.history.replace({ pathname: '/publicize' })
            }, 1000)
          })
        }
      })
    } else {
      //登录
      let self = this;
      loginService.login({
        params: {
          phone: this.state.phone,
          captcha: this.state.code,
        },
        onSuccess: ({ data }) => {
          setPhoneDao(29);
          if (this.state.uniqueId) {
            window.localStorage.setItem('lq-pocket-uniqueId', this.state.uniqueId)
          }
          Toast.success.call(self, '登录成功', 1, _ => {
            setTimeout(_ => {
              self.props.history.replace({ pathname: '/publicize' })
            }, 1000)
          })
        }
      })
    }
  }
  state = {
    type: '',
    str: '获取验证码',
    name: '',
    phone: '',
    code: '',
    uniqueId: null
  }
  componentWillMount() {
    let { type } = qs.parse(this.props.location.search)
    setTitle(type === 'regist' ? '手机号注册' : '手机号登录')
  }
  componentDidMount() {
    let { type, name, appid, uniqueId } = qs.parse(this.props.location.search)
    this.setState({
      type,
      name,
      appid,
      uniqueId
    })
  }
  sendCode() {
    if (this.state.str !== '获取验证码') return
    //发送短信
    if (!/^1[0-9]{10}$/.test(this.state.phone)) {
      Toast.fail('请输入正确的手机号', 1)
      return
    }
    if (this.state.type === 'regist') {
      //注册验证码
      loginService.regCode({
        params: { phone: this.state.phone },
        onSuccess: ({ data }) => {
          if (data.result.error === 0) {
            Toast.success('短信已发送', 2)
            this.countSecond()
          } else {
            Toast.fail('短信发送失败', 2)
          }
        }
      })
    } else {
      //登录验证码
      loginService.loginCode({
        params: { phone: this.state.phone },
        onSuccess: ({ data }) => {
          if (data.result.error === 0) {
            Toast.success('短信已发送', 2)
            this.countSecond()
          } else {
            Toast.fail('短信发送失败', 2)
          }
        },
        onError: (err) => {
          console.log(err)
        }
      })
    }

  }
  countSecond() {
    this.setState({
      str: 30
    })
    sInterval = setInterval(_ => {
      if (this.state.str === 1) {
        window.clearInterval(sInterval)
        this.setState({
          str: '获取验证码'
        })
        return
      }
      this.setState({
        str: this.state.str - 1
      })
    }, 1000)
  }
  onBlur = (obj) => {//只有ios键盘收起时会触发失去焦点事件，andriod键盘收起不触发失去焦点事件
    // $('.historyBtn,.footer').css({'opacity':'1','display':'block','position':'fixed'})//解决ios 固定定位随着键盘弹起的问题
    window.scroll(0, window.scrollY) //解决键盘收起页面卡住不会落问题
  }
  componentWillUnmount() {
    window.clearInterval(sInterval)
  }
  render() {
    return (
      <div className="login">
        <div className="login-cell">
          <div>
            <img alt="" src={[require("@/assets/login_icon_cell@3x.png")]} />
            <span>手机号</span>
          </div>
          <div className="login-ip">
            <input onBlur={this.onBlur} maxLength="11" onChange={(e) => this.setState({ phone: e.target.value })} className="input-ip" placeholder='请输入您的手机号' />
          </div>
        </div>
        <div className="login-cell">
          <div>
            <img alt="" src={[require("@/assets/login_icon_key@3x.png")]} />
            <span>验证码</span>
          </div>
          <div className="login-ip">
            <div>
              <input maxLength={6} onBlur={this.onBlur} onChange={(e) => this.setState({ code: e.target.value })} className="input-ip" placeholder='请输入您收到的6位验证码' />
            </div>
            <div className="code" style={{ color: (this.state.str !== '获取验证码') ? '#A6ABC2' : '', borderColor: (this.state.str !== '获取验证码') ? '#A6ABC2' : '' }} onClick={this.sendCode.bind(this)}>{this.state.str}</div>
          </div>
        </div>
        <div className="login-btn act-btn" onClick={this.login.bind(this)}>{this.state.type === 'regist' ? '确认注册' : '确认登录'}</div>
      </div>
    )
  }
}