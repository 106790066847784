import { BrowserRouter as Router, Route } from 'react-router-dom'
import React from 'react';
import Loadable from "react-loadable"

function Routes() {
  return (
    <Router>
      <Route exact path="/" component={Loadable({
        loader: () => import('@/views/entrance'),
        loading: () => <div />
      })} />
      <Route exact path="/prepare" component={Loadable({
        loader: () => import('@/views/prepare'),
        loading: () => <div />
      })} />
      <Route exact path="/login" component={require('@/views/login').default} />
      <Route path="/publicize" component={require('@/views/main').default} />
      <Route exact path="/publicize" component={require('@/views/main/publicize').default} />
      <Route exact path="/publicize/income" component={require('@/views/main/income').default} />
      <Route exact path="/withdrawRecord" component={require('@/views/main/income/withdrawRecord').default} />
      <Route exact path="/bounty" component={require('@/views/main/income/bounty').default} />
      <Route exact path="/publicize/shop" component={require('@/views/main/shop').default} />
      <Route exact path="/withdraw" component={require('@/views/main/income/withdraw').default} />
      <Route exact path="/total" component={require('@/views/main/income/total').default} />
      <Route exact path="/order" component={require('@/views/order').default} />
      <Route exact path="/order/detail" component={require('@/views/order/detail').default} />
      <Route exact path="/order/pay" component={require('@/views/order/pay').default} />
      <Route exact path="/order/address" component={require('@/views/order/address').default} />
      <Route exact path="/partake" component={require('@/views/partake').default} />
      <Route exact path="/strategy" component={require('@/views/main/strategy').default} />
    </Router>
  )
}
export default Routes;