import c2s from '@/utils/http';

const baseService = {    
  getJSSdk (option) {      
      return c2s({
        url:`/api/profiles`,
        ...option,
      },{mask:false,silentError:true});    
  },
  getAddress (option) {      
    return c2s({
      url:`/api/divisions`,
      ...option,
    },{mask:false,silentError:true});    
  },
  qrcode (option) {      
    return c2s({
      url:`/api/program/qrcode`,
      ...option,
    },{mask:true,silentError:true});    
  },
}

export default baseService;