
import * as types from "./action-types";

export default {
  setErr(bol, msg, icon, title) {
    return { type: types.SETERR, bol: bol, msg: msg, icon: icon, title: title }
  },
  setPhone(phone) {
    return { type: types.SETPHONE, phone: phone }
  },
  setAutoLogin(bol) {
    return { type: types.SETAUTOLOGIN, bol: bol }
  },
  setIsShowedNewSchemeToast(bol) {
    return { type: types.SETISSHOWEDNEWSCHEMETOAST, bol: bol }
  },
  setPostUrl(url) {
    return { type: types.SETPOSTURL, url: url }
  },
}