import React from 'react';
import './App.scss';
import Router from '@/routes/router'
import Errtip from '@/components/errTip'
import VConsole from 'vconsole/dist/vconsole.min.js'
import sdk from '@/utils/sdk'
new sdk()
if (process.env.NODE_ENV === 'development') {
  new VConsole(); // 初始化
}
function App() {
  return (
    <div className="App">
      <Router />
      <Errtip />
    </div>
  );
}

export default App;
