import React from 'react'
import { Prompt } from "react-router-dom"
import './index.scss'
import FootBar from './components/footBar'
export default class Main extends React.Component {
  state = {
    isKeyBoardFixed: true,
    bodyHeight: null
  }
  resize() {
    this.setState({
      bodyHeight: document.documentElement.clientHeight || document.body.scrollHeight
    })
    if (document.body.scrollHeight > this.state.bodyHeight) {
      document.body.style.height =
        this.setState({
          isKeyBoardFixed: false
        })
    } else {
      this.setState({
        isKeyBoardFixed: true
      })
    }
  }
  componentDidUpdate() {
    window.addEventListener('resize', this.resize.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this))
  }
  render() {
    return (
      <div className="main">
        {this.state.isKeyBoardFixed && <FootBar />}
        <Prompt message={location => {
          if (location.pathname === "/prepare") {
            window.mixSDK.backNative();
            return false;
          }
          return true;
        }} />
      </div>

    )
  }
}