import React from "react";
import { Toast } from "antd-mobile";
import { withRouter } from "react-router-dom";
class Money extends React.Component {
  state = {
    visible: false,
    tip:
      "1、未成交（待退款和已退款）的订单，你将不会获得佣金；\n2、结算周期为T+7，即邀请用户今日下单，您在7日之后方可提现该金额。"
  };
  // 1、邀请用户支付电子证件照订单，你将获得该笔订单收入的30%作为佣金；邀请用户支付冲印订单每支付1单，你将获得2元佣金；\n
  closeTip(bol, event) {
    event.stopPropagation();
    this.setState({
      visible: bol
    });
  }
  goWithdraw() {
    if (this.props.withdraw === "0.00") {
      Toast.fail("暂时没有可提现金额哦", 2);
      return;
    }
    this.props.history.push(`/withdraw?money=${this.props.withdraw}`);
  }
  goWithdrawRecord() {
    this.props.history.push(`/withdrawRecord`);
  }
  goTotal() {
    this.props.history.push("/total");
  }
  goBounty() {
    this.props.history.push("/bounty");
  }
  render() {
    return (
      <div className="money" style={{ height: "216px" }}>
        <div style={{ height: "12px", width: "100%" }}></div>
        <div
          style={{
            width: "343px",
            height: "204px",
            margin: "0 auto",
            backgroundColor: "#F7F8F9",
            borderRadius: "4px"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100px",
              marginLeft: "26px",
              marginRight: "26px"
            }}
          >
            <div>
              <div style={{ fontSize: "32px", color: "#242E5F", fontWeight: "bold" }}>
                {this.props.withdraw}
              </div>
              <div
                onClick={this.closeTip.bind(this, true)}
                style={{
                  position: "relative",
                  fontSize: "12px",
                  color: "#848BB0",
                  marginLeft: "2px",
                  marginTop: "6px"
                }}
              >
                可提现金额(元)
                <img
                  alt=""
                  style={{
                    marginLeft: "4px",
                    width: "13px",
                    verticalAlign: "top"
                  }}
                  src={[require("@/assets/money_icon_doubt@3x.png")]}
                />
                {this.state.visible && (
                  <div
                    style={{
                      whiteSpace: "pre-line",
                      borderRadius: "4px",
                      width: "328px",
                      position: "absolute",
                      top: "20px",
                      left: "-20px",
                      backgroundColor: "#4F577E",
                      fontSize: "12px",
                      lineHeight: "20px",
                      color: "#fff",
                      zIndex: 1
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-13px",
                        left: "50px",
                        width: "0",
                        height: "0",
                        border: "8px solid transparent",
                        borderBottom: "8px solid #4F577E"
                      }}
                    ></div>
                    <div style={{ margin: "16px 12px" }}>{this.state.tip}</div>
                    <div
                      onClick={this.closeTip.bind(this, false)}
                      style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100vw",
                        height: "100vh",
                        zIndex: "3"
                      }}
                    ></div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div
                className="act-btn"
                onClick={this.goWithdraw.bind(this)}
                style={{
                  width: "88px",
                  height: "36px",
                  backgroundColor: "#DE0073",
                  borderRadius: "18px",
                  color: "#fff",
                  fontSize: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                提现
            </div>
              <div
                onClick={this.goWithdrawRecord.bind(this)}
                style={{
                  fontSize: "12px",
                  color: "#848BB0",
                  marginLeft: "2px",
                  marginTop: "6px",
                  textAlign: "center",
                  paddingLeft: "16px"
                }}>
                提现记录
                <img
                  alt=""
                  style={{
                    marginLeft: "4px",
                    width: "13px",
                    verticalAlign: "top"
                  }}
                  src={[require("@/assets/poster_icon_more@3x.png")]}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              height: "1px",
              width: "286px",
              borderTop: "1px dashed #E4E6F1",
              marginLeft: "28px"
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100px",
              marginLeft: "26px",
              marginRight: "26px"
            }}
          >
            <div onClick={this.goTotal.bind(this)}>
              <div style={{ fontSize: "20px", paddingLeft: '2px', color: "#242E5F", fontWeight: "bold" }}>
                {this.props.totalIncome}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#848BB0",
                  marginLeft: "2px",
                  marginTop: "2px"
                }}
              >
                总收入(元)
                <img
                  alt=""
                  style={{
                    marginLeft: "4px",
                    width: "13px",
                    verticalAlign: "top"
                  }}
                  src={[require("@/assets/poster_icon_more@3x.png")]}
                />
              </div>
            </div>
            <div>
              <div style={{ fontSize: "20px", paddingLeft: '2px', color: "#242E5F", fontWeight: "bold" }}>
                {this.props.commission}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#848BB0",
                  marginLeft: "2px",
                  marginTop: "2px"
                }}
              >
                佣金(元)
              </div>
            </div>
            <div onClick={this.goBounty.bind(this)}>
              <div style={{ fontSize: "20px", paddingLeft: '2px', color: "#242E5F", fontWeight: "bold" }}>
                {this.props.bonus}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#848BB0",
                  marginLeft: "2px",
                  marginTop: "2px"
                }}
              >
                奖励金(元)
                <img
                  alt=""
                  style={{
                    marginLeft: "4px",
                    width: "13px",
                    verticalAlign: "top"
                  }}
                  src={[require("@/assets/poster_icon_more@3x.png")]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Money);
