import EventEmitter from "./EventEmitter";

let loginSuccessSubscription = null;
let loginFailSubscription = null;
let paySubscription = null;
const wxScriptUrl = "https://res.wx.qq.com/open/js/jweixin-1.3.2.js";
const aliScriptUrl = "https://appx/web-view.min.js";
export default class sdk {
  constructor() {
    console.log("app skd constructor", window["__wxjs_environment"] === "miniprogram" ||
      /microMessenger/i.test(window.navigator.userAgent), /AliApp\((UC|QUARK)/.test(window.navigator.userAgent) || window.navigator.userAgent.indexOf("Alipay") > -1);
    if (
      window["__wxjs_environment"] === "miniprogram" ||
      /microMessenger/i.test(window.navigator.userAgent)
    ) {
      this.loadWXSDK();
    } else if (/AliApp\((UC|QUARK)/.test(window.navigator.userAgent) || window.navigator.userAgent.indexOf("Alipay") > -1) {
      this.loadAliSDK();
    } else {
      this.onloadSDK();
    }
  }
  loadAliSDK() {
    const el = document.createElement("script");
    el.type = "text/javascript";
    el.async = true;
    el.src = aliScriptUrl;
    el.onload = () => {
      console.log("Ali skd load");
    };
    document.head.appendChild(el);
    try {
      window.mixSDK = {
        shareToWx(code, url, content) {
          url = encodeURIComponent(url);
          window.my.postMessage({ name: "share", code, url, content });
        },
        backNative() {
          window.my.postMessage({ name: "backNative" });
        },
        loginWx(args) {
          args.success(window.localStorage.getItem("lq-pocket-uniqueId"));
        },
        savePoster(url) {
          url = encodeURIComponent(url);
          window.my.postMessage({ name: "savePoster", url });
        },
        askWechatRequest(paymentParam, success) {
          window.my.postMessage({ name: "payPoster", paymentParam });
          window.my.onMessage = function (e) {
            console.log(e); //{'sendToWebView': '1'}
            if (e.type === "payPoster" && e.result === "success") success();
          };
        }
      };
    } catch (e) {
      console.log(e);
    }
  }
  loadWXSDK() {
    const el = document.createElement("script");
    el.type = "text/javascript";
    el.async = true;
    el.src = wxScriptUrl;
    el.onload = () => {
      console.log("wx skd load");
    };
    document.head.appendChild(el);
    try {
      window.mixSDK = {
        shareToWx(index, url, content) {
          url = encodeURIComponent(url);
          window.wx.miniProgram.navigateTo({
            url: `/pages/miniApi/miniApi?description=shareToWx&url=${url}&content=${content}`
          });
        },
        backNative() {
          window.wx.miniProgram.reLaunch({
            url: "/pages/index/index"
          });
        },
        loginWx(args) {
          args.success(window.localStorage.getItem("lq-pocket-uniqueId"));
        },
        savePoster(url) {
          url = encodeURIComponent(url);
          window.wx.miniProgram.navigateTo({
            url: `/pages/miniApi/miniApi?description=savePoster&url=${url}`
          });
        },
        askWechatRequest(paymentParam, success) {
          let url = "/pages/miniApi/miniApi?description=askWechatRequest";
          Object.keys(paymentParam).forEach(item => {
            if (item === "package") {
              let p = paymentParam[item].replace("prepay_id=", "");
              url += `&${item}=${p}`;
            } else {
              url += `&${item}=${paymentParam[item]}`;
            }
          });
          window.wx.miniProgram.navigateTo({
            url: url
          });
        }
      };
    } catch (e) {
      console.log(e);
    }
  }
  onloadSDK() {
    console.log("app skd load");
    const event = new EventEmitter();
    console.log("window.android", window.android)
    if (!window.android) {
      try {
        window.mixSDK = {
          /**
           * wechat share
           * @param {*} index 0friend,1circle
           * @param {*} url img url
           * @param {*} content copy font
           */
          shareToWx(index, url, content) {
            window.webkit.messageHandlers.shareToWx.postMessage({
              index: index,
              url: url,
              content: content
            });
          },
          backNative() {
            window.webkit.messageHandlers.backNative.postMessage(true);
          },
          loginWx(args) {
            window.webkit.messageHandlers.loginWx.postMessage(true);
            loginSuccessSubscription = event.subscribe(
              "THUNDER_ON_THE_LOGINSUCCESS",
              value => args.success(value)
            );
            loginFailSubscription = event.subscribe(
              "THUNDER_ON_THE_LOGINFAIL",
              errMsg => args.fail(errMsg)
            );
          },
          loginSuccessCallBack(code) {
            event.emit("THUNDER_ON_THE_LOGINSUCCESS", code);
            loginSuccessSubscription.unsubscribe();
          },
          loginFailCallBack(errMsg) {
            event.emit("THUNDER_ON_THE_LOGINFAIL", errMsg);
            loginFailSubscription.unsubscribe();
          },
          savePoster(url) {
            window.webkit.messageHandlers.savePoster.postMessage(url);
          },
          posterCallBack(bol) {
            console.log(bol);
          },
          askWechatRequest(paymentParam, success) {
            console.log("window.webkit", window.webkit)
            window.webkit.messageHandlers.askWechatRequest.postMessage(
              paymentParam
            );
            paySubscription = event.subscribe("THUNDER_ON_THE_PAY", value =>
              success(value)
            );
          },
          wxPayCallBack(result) {
            event.emit("THUNDER_ON_THE_PAY", result);
            paySubscription.unsubscribe();
          }
        };
      } catch (e) {
        console.log(e);
      }
    } else {
      window.mixSDK = {
        /**
         * wechat share
         * @param {*} index 0friend,1circle
         * @param {*} url img url
         * @param {*} content copy font
         */
        shareToWx(index, url, content) {
          window.android.shareToWx(index, url, content);
        },
        backNative() {
          window.android.backNative();
        },
        loginWx(args) {
          window.android.loginWx();
          loginSuccessSubscription = event.subscribe(
            "THUNDER_ON_THE_LOGINSUCCESS",
            value => args.success(value)
          );
          loginFailSubscription = event.subscribe(
            "THUNDER_ON_THE_LOGINFAIL",
            errMsg => args.fail(errMsg)
          );
        },
        savePoster(url) {
          window.android.savePoster(url);
        },
        askWechatRequest(paymentParam, success) {
          window.android.askWechatRequest(
            paymentParam.noncestr,
            paymentParam.partnerid,
            paymentParam.prepayid,
            paymentParam.timestamp,
            paymentParam.sign,
            paymentParam.appid,
            paymentParam.package
          );
          paySubscription = event.subscribe("THUNDER_ON_THE_PAY", value =>
            success(value)
          );
        }
      };
      window.androidCallBack = {
        loginSuccessCallBack(code) {
          event.emit("THUNDER_ON_THE_LOGINSUCCESS", code);
          loginSuccessSubscription.unsubscribe();
        },
        loginFailCallBack(errMsg) {
          event.emit("THUNDER_ON_THE_LOGINFAIL", errMsg);
          loginFailSubscription.unsubscribe();
        },
        wxPayCallBack(result) {
          event.emit("THUNDER_ON_THE_PAY", result);
          paySubscription.unsubscribe();
        }
      };
    }
  }
}
