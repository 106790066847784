import c2s from '@/utils/http';

const shopService = {
  closeShop(option) {
    return c2s({
      method: 'post',
      url: `/api/store/off`,
      ...option,
    }, { mask: true, silentError: true });
  },
  changeShopName(option) {
    return c2s({
      method: 'post',
      url: `/api/shop_name`,
      ...option,
    }, { mask: true, silentError: true });
  },
  userInfo(option) {
    return c2s({
      url: `/api/user/info`,
      ...option,
    }, { mask: true, silentError: true });
  },
  uploadHead(option) {
    return c2s({
      url: `/api/photostudio/avatar`,
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data'
      },
      ...option,
    }, { mask: true, silentError: true });
  },
}

export default shopService;