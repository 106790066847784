import c2s from "@/utils/http";

const incomeService = {
  getMoney(option) {
    return c2s(
      {
        method: "post",
        url: `/api/enterprise/payment`,
        ...option
      },
      { mask: true, silentError: true }
    );
  },
  getAliMoney(option) {
    return c2s(
      {
        method: "post",
        url: `/api/alipay/transfer`,
        ...option
      },
      { mask: true, silentError: true }
    );
  },
  getInfo(option) {
    return c2s(
      {
        url: `/api/index/income`,
        ...option
      },
      { mask: true, silentError: true }
    );
  },
  getRecordList(option) {
    return c2s(
      {
        url: `/api/orders/record`,
        ...option
      },
      { mask: true, silentError: true }
    );
  },
  getAnalysis(option) {
    return c2s(
      {
        url: `/api/sort/income`,
        ...option
      },
      { mask: true, silentError: true }
    );
  },
  getInitialization(option) {
    return c2s(
      {
        url: `/api/task/initialization`,
        ...option
      },
      { mask: true, silentError: true }
    )
  },
  getCashOutRecord(option) {
    return c2s(
      {
        url: `/api/cash-in/record`,
        ...option
      },
      { mask: true, silentError: true }
    )
  },
  getBonusDetail(option) {
    return c2s(
      {
        url: `/api/bonus/detail`,
        ...option
      },
      { mask: true, silentError: true }
    )
  },

};

export default incomeService;
