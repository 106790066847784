import React, { useEffect, useState } from 'react';
import './getBoundMask.scss';
export default function GetBoundMask({ isMissionFinshArr, close }) {
    const [animation, setAnimation] = useState(false)
    const [isShow, { boundNum } = {}] = [
        isMissionFinshArr.some(item => (item.isFinsh === true)),
        isMissionFinshArr.find(item => (item.isFinsh === true))
    ]
    useEffect(() => {
        setAnimation(true)
    }, [])
    return (
        <>
            {isShow && <div className="getBoundMask" onClick={close}>
                <div key={boundNum} className={animation ? `content bounceIn` : `content`}>
                    <div className="giftWrap">
                        <img className="gift" src={[require("@/assets/pop_box_open@2x.png")]} alt="" />
                        <div className="boundNum">
                            <div className="num">{boundNum} <span>元</span></div>
                            <div className="text">奖励金</div>
                        </div>
                    </div>
                    <div className="mask">恭喜您获得 <span>{boundNum}</span> 元！</div>
                </div>
            </div>}
        </>
    )
}