import c2s from '@/utils/http';

const loginService = {
  regCode(option) {
    return c2s({
      url: `/api/registry/captcha`,
      ...option,
    }, { mask: false, silentError: true });
  },
  loginCode(option) {
    return c2s({
      url: `/api/login/captcha`,
      ...option,
    }, { mask: false, silentError: true });
  },
  regist(option) {
    return c2s({
      method: 'post',
      url: `/api/store/registry`,
      ...option,
    }, { mask: true, silentError: true });
  },
  login(option) {
    return c2s({
      method: 'post',
      url: `/api/store/login`,
      ...option,
    }, { mask: true, silentError: true });
  },
  getIndexGif(option) {
    return c2s({
      url: '/api/index/viewpager',
      ...option
    }, { mask: true, silentError: true })
  }
}

export default loginService;