import * as types from "./action-types";
export default (state, action) => {
  switch (action.type) {
    case types.SETERR:
      // return { errFlag: action.bol,errMsg:action.msg,icon:action.icon,title:action.title};
      return Object.assign({}, state, {
        errFlag: action.bol, errMsg: action.msg, icon: action.icon, title: action.title
      });
    case types.SETPHONE:
      return Object.assign({}, state, {
        phone: action.phone
      });
    case types.SETAUTOLOGIN:
      return Object.assign({}, state, {
        autologin: action.bol
      });
    case types.SETPOSTURL:
      return Object.assign({}, state, {
        postUrl: action.url
      });
    case types.SETISSHOWEDNEWSCHEMETOAST:
      return Object.assign({}, state, {
        isShowedNewSchemeToast: action.bol
      });
    default:
      return state
  }
}