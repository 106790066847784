import React from 'react'
import './index.scss'
import { Toast } from 'antd-mobile';
export default class Prepare extends React.Component {
  state = {
    name: ''
  }
  goLogin(type) {
    if (type === 'regist' && this.state.name === '') {
      Toast.info('请输入店名', 1)
      return
    }
    if (this.state.name.length > 10) {
      Toast.fail('店名不能超过10个字~请重新输入！', 2)
      return
    }
    let query = type === 'regist' ? `${this.props.location.search}&type=${type}&name=${this.state.name}` : `${this.props.location.search}&type=${type}`
    this.props.history.push({ pathname: `/login`, search: `${query}` })
  }
  render() {
    return (
      <div className="prepare">
        <div className="prepare-title">开店准备工作</div>
        <img className="prepare-line" alt="" src={[require("@/assets/line@3x.png")]} />
        <div className="prepare-tips">请先起一个店名吧</div>
        <div className="prepare-input">
          <div className="input-bank">
            <img className="input-img" alt="" src={[require("@/assets/login_icon_write@3x.png")]} />
          </div>
          <div className="input-wb">
            <input value={this.state.name} maxLength={10} onChange={(e) => this.setState({ name: e.target.value })} className="input-ip" placeholder='例如：✕✕✕照相馆（不超过10个字）' />
          </div>
        </div>
        <img className="prepare-img" alt="" src={[require("@/assets/login_bg_wenli@3x.png")]} />
        <div onClick={this.goLogin.bind(this, 'regist')} className="prepare-btn act-btn">手机号注册</div>
        <div onClick={this.goLogin.bind(this, 'login')} className="prepare-login">已有相馆？立即登录</div>
        <div className="prepare-foot">温馨提示：手机号主要用于识别您的用户来源，进行订单收入结算 </div>
      </div>
    )
  }
}