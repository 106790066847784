import React from 'react'
import store from "@/store/store";
import { clearErr } from '@/utils/tools';
import {removePhoneDao} from "@/utils/dao.js";
export default class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isshow: store.getState().errFlag,
      icon: store.getState().icon,
      errMsg: store.getState().errMsg,
      title: store.getState().title || '温馨提示',
    }
  }
  clear() {
    clearErr();
    if(this.state.errMsg==='该用户不存在！'){
      removePhoneDao();
      window.location.href="/?relink=1"
    }
  }
  render() {
    store.subscribe(() =>
      this.setState({
        isshow: store.getState().errFlag,
        icon: store.getState().icon,
        errMsg: store.getState().errMsg,
        title: store.getState().title || '温馨提示',
      })
    )
    return (
      <div className="errTip" style={{ position: "relative", zIndex: 10 }}>
        {this.state.isshow &&
          <div>
            <div style={{ height: '100vh', width: '100vw', backgroundColor: 'rgba(0,0,0,1)', opacity: '0.4', position: 'fixed', left: '0', top: '0' }}></div>
            <div style={{ position: 'fixed', top: '200px', width: '100vw', display: 'flex', justifyContent: 'center' }}>
              <div style={{ height: '265px', width: '305px', backgroundColor: 'rgba(255,255,255,1)', borderRadius: '4px', textAlign: 'center' }}>
                <div style={{ fontSize: '20px', fontWeight: '500', color: 'rgba(36,46,95,1)', lineHeight: '28px', marginTop: '24px' }}>{this.state.title}</div>
                <img style={{ width: '58px', marginTop: '25px' }} alt="" src={this.state.icon === 'err' ? [require("@/assets/pop_icon_error@3x.png")] : [require("@/assets/pop_icon_successful@3x.png")]} />
                <div style={{ fontSize: '14px', color: '#242E5F', lineHeight: '20px', marginTop: '16px' }}>{this.state.errMsg}</div>
                <div className="act-btn" onClick={this.clear.bind(this)} style={{ color: '#fff', textAlign: 'center', lineHeight: '46px', width: '260px', height: '46px', margin: '0 auto', marginTop: '24px', background: 'linear-gradient(146deg,rgba(222,0,115,1) 0%,rgba(222,0,115,1) 100%)', borderRadius: '23px' }}>确定</div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}