import React from "react";

export default class NewSchemeToast extends React.Component {
    render() {
        return (
            <div className='mask' style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0 }}>
                <div className='circle' style={{
                    width: '95px',
                    height: '95px',
                    borderRadius: '50%',
                    border: '100vh solid rgba(0, 0, 0, 0.32)',
                    position: 'absolute',
                    top: '166px',
                    left: 'calc(50% + 105px)',
                    transform: 'translate(-50%, -50%)',
                    zIndex: '-1'
                }} onClick={_ => { this.props.closeToast() }} ></div>
                <div style={{
                    position: 'absolute', top: '220px', left: '50%', transform: 'translateX(-50%)',
                }}>
                    <img
                        style={{ width: '12px', float: "right", marginRight: '43px' }}
                        alt=""
                        src={[require("@/assets/new_guide_line@2x.png")]}
                    />
                    <img style={{ width: '310px' }} onClick={_ => { this.props.closeToast(); this.props.goBounty() }} alt="" src={[require("@/assets/new_guide_image@2x.png")]} />
                </div>
            </div>
        )
    }
}