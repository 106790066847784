import React from 'react'
export default class Tip extends React.Component{
  render(){
    return(
      <div style={{width:'347px',height:'38px',backgroundColor:'#FFF4F9',margin:'0 auto',display:'flex',alignItems:'center',borderRadius:'2px'}}>
        <img style={{width:'15px',marginLeft:'8px'}} alt="" src={[require("@/assets/login_icon_write@3x.png")]} />
        <span style={{fontSize:'13px',color:'#DE0073',marginLeft:'2px'}}>用户的积累、收入的增长，靠长期持续不断的宣传</span>
      </div>
    )
  }
}