import React from 'react'
import { createForm } from 'rc-form';
import { Picker, List,TextareaItem,Toast } from 'antd-mobile';
import './index.scss'
import baseService from '@/service/baseService'
class Address extends React.Component{
  state={
    district:[],
    name:'',
    phone:'',
    selectCode:[],
    detail:''
  }
  save(){
    //验证数据
    if(!this.state.name){
      Toast.fail('请输入姓名!', 1);
    } else if(!/^1[0-9]{10}$/.test(this.state.phone)) {
      Toast.fail('请输入正确的手机号!', 1);
    } else if(this.state.selectCode.length===0) {
      Toast.fail('请选择收货地区!', 1);
    } else if(!this.state.detail) {
      Toast.fail('请输入详细地址!', 1);
    }else{
      //开始缓存
      let addressValue = ''
      let addressCode = ''
      if (this.state.selectCode[2] === undefined) {
        //直辖市
        addressValue = this.state.initData[this.state.selectCode[0]] + this.state.initData[this.state.selectCode[1]]
        addressCode = this.state.selectCode[1]
      }else{
        addressValue = this.state.initData[this.state.selectCode[0]] + this.state.initData[this.state.selectCode[1]] + this.state.initData[this.state.selectCode[2]]
        addressCode = this.state.selectCode[2]
      }
      let obj = {name:this.state.name,phone:this.state.phone,detail:this.state.detail,selectCode:this.state.selectCode,addressValue:addressValue,addressCode:addressCode}
      window.localStorage.setItem('lq-pocket-addr',JSON.stringify(obj))
      this.props.history.goBack()
    }
  }
  componentDidMount(){
    this.rangeAddr()
    this.checkAddr()
  }
  checkAddr(){
    let addr = JSON.parse(window.localStorage.getItem('lq-pocket-addr'))
    if(!addr){
      return
    }
    this.setState({
      name:addr.name,
      phone:addr.phone,
      selectCode:addr.selectCode,
      detail:addr.detail
    })
  }
  rangeAddr(){
    baseService.getAddress({
      onSuccess: ({ data }) => {
        //区分省市区
        this.setState({
          initData:data
        })
        let province_list = []
              , city_list = []
              , area_list = []
              , addr_data = []
        for (let value in data) {
          // if (+key === 710000 || +key === 810000 || +key === 820000) {
          //   continue;
          // }
          let label = data[value]
          if (/0000$/.test(value)) {
            province_list.push({value, label,})
          } else if (/00$/.test(value)) {
            city_list.push({value, label,})
          } else {
            area_list.push({value, label,})
          }
        }
        addr_data = [[...province_list], [...city_list], [...area_list]]
        this.choiceChild(addr_data)
      }
    })
  }
  choiceChild(data){
    let arr = data[0]
    arr.forEach((element,index) => {
      //抽省
      element.children = data[1].filter(r=>r.value.slice(0,2) === element.value.slice(0,2))
      if(element.children.length===0){
        element.children = data[2].filter(r=>r.value.slice(0,2) === element.value.slice(0,2));
      }else{
        element.children.forEach((e,i)=>{
          //抽市
          e.children = data[2].filter(r=>r.value.slice(0,4) === e.value.slice(0,4))
        })
      }
    });
    this.setState({
      district:arr
    })
  }
  render(){
    const { getFieldProps } = this.props.form;
    return(
      <div className="address">
        <div className="bank"></div>
        <div className="detail-div">
          <div className="row">
            <div className="label">联系人</div>
            <div className="value"><input value={this.state.name} onChange={(e)=>this.setState({name:e.target.value})} className="input-ip" placeholder='请填写您的姓名' /></div>
          </div>
          <div className="row">
            <div className="label">手机号码</div>
            <div className="value"><input value={this.state.phone} onChange={(e)=>this.setState({phone:e.target.value})} className="input-ip" placeholder='请填写您的手机号' /></div>
          </div>
          {/* <div className="row">
            <div className="label">选择地区</div>
            <div className="value">江苏省无锡市滨金融街一街11号</div>
          </div> */}
            <Picker
              data={this.state.district}
              title="请选择地区"
              extra="请选择"
              {...getFieldProps('district', {
                initialValue: this.state.selectCode,
              })}
              onOk={e => this.setState({selectCode:e})}
              onDismiss={e => console.log('dismiss', e)}
            >
              <List.Item arrow="horizontal">选择地区</List.Item>
            </Picker>
          {/* <div className="row">
            <div className="label">详细地址</div>
            <div className="value"><input className="input-ip" placeholder='请填写街道、小区门牌等详细地址' /></div>
          </div> */}
          <TextareaItem
            onChange={(e)=>this.setState({detail:e})}
            value={this.state.detail}
            title="详细地址"
            placeholder="请填写街道、小区门牌等详细地址"
            data-seed="logId"
            ref={el => this.autoFocusInst = el}
            autoHeight
          />
        </div>
        <div className="foot">
          <div className="btn" onClick={this.save.bind(this)}>保存并使用</div>
        </div>
      </div>
    )
  }
}
const TestWrapper = createForm()(Address);
export default TestWrapper;