import React from "react";
import "./index.scss";
import { Carousel } from "antd-mobile";
import store from "@/store/store";
import action from "@/store/action";
import loginService from '@/service/loginService';
import { showErr } from '@/utils/tools';
import {getPhoneDao} from "@/utils/dao.js";
const qs = require("query-string");
// import baseService from '@/service/baseService'
// const qs = require('query-string');
export default class Entrance extends React.Component {
  state = {
    data: [
      {
        title: "简历照",
        content:
          "找工作想在简历上放一张美美的证件照，提高面试概率，可市中心那家最美证件照也太贵了！",
        name: "陈涵予",
        work: "求职者",
        icon: [require("@/assets/inde_people_one@3x.png")]
      },
      {
        title: "普通话报名",
        content:
          "普通话考试要一张电子照，去找家相馆，来来回回要耽误两个小时，而且电子照价格还很贵。",
        name: "刘浩珉",
        work: "学生",
        icon: [require("@/assets/inde_people_two@3x.png")]
      },
      {
        title: "一寸蓝底",
        content:
          "女儿的学校要上交一张证件照，等到周末来不及，下了班去照相馆都打烊了，只能请假去拍了。",
        name: "陈寒",
        work: "上班族",
        icon: [require("@/assets/inde_people_three@3x.png")]
      },
      {
        title: "社保照",
        content:
          "刚出生的小孩需要一张社保照，孩子这么小带出去拍照好麻烦，请摄影师到家里又特别贵。",
        name: "刘阿敏",
        work: "妈妈",
        icon: [require("@/assets/inde_people_four@3x.png")]
      }
    ]
  };
  componentDidMount() {
    //如果之前登录过,触发自动登录
    this.autoLogin();
    this.getGif()
  }
  autoLogin() {
    if (window.navigator.userAgent.indexOf("Alipay") > -1) {
      let { aliPath } = qs.parse(this.props.location.search);
      if (aliPath) {
        window.localStorage.setItem("lq-pocket-aliPath", aliPath);
      } else {
        window.localStorage.removeItem("lq-pocket-aliPath");
      }
    }
    let phone = getPhoneDao();
    if (!phone) {
      return;
    }
    if (!store.getState().autologin) {
      return;
    }
    let { uniqueId } = qs.parse(this.props.location.search);
    if (uniqueId) {
      window.localStorage.setItem("lq-pocket-uniqueId", uniqueId);
    }
    this.props.history.replace("/publicize");
  }
  getGif() {
    let self = this;
    loginService.getIndexGif({
      // params:{},
      onSuccess: ({ data: { result } }) => {
        result.forEach((item, idx) => {
          self.state.data[idx].img = item;
        })
        this.setState({
          data: self.state.data
        })
      }
    })
  }
  openCamera() {
    //不自动登录
    store.dispatch(action.setAutoLogin(false));
    if (!this.props.location.search) {
      showErr("请在正确终端进行注册！", "提示")
      return;
    }
    this.props.history.replace("/prepare" + this.props.location.search);
  }
  render() {
    return (
      <div className="entrance">
        <div className="entrance-title">Hi~ 亲爱的</div>
        <div className="entrance-content">
          您有没有想过，其实您身边有很多的人跟您一样需要拍摄证件照，但是他们还在：
        </div>
        <Carousel
          className="entrance-coll"
          infinite={true}
          autoplay={true}
          autoplayInterval={4000}
          // beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
          // afterChange={index => console.log('slide to', index)}
          dotStyle={{
            width: "14px",
            height: "3px",
            borderRadius: "2px",
            opacity: "0.2",
            backgroundColor: "#252F60"
          }}
          dotActiveStyle={{
            width: "15px",
            height: "3px",
            borderRadius: "2px",
            opacity: "0.5",
            backgroundColor: "#252F60"
          }}
        >
          {this.state.data.map((val, index) => (
            <div className="coll-div" key={index}>
              <div className="title">{val.title}</div>
              <img className="coll-gif" alt="" src={val.img} />
              <div className="content">{val.content}</div>
              <div className="coll-foot">
                <div className="name">
                  <img alt="" src={val.icon} />
                  <span>{val.name}</span>
                </div>
                <div className="work">
                  <img
                    alt=""
                    src={[require("@/assets/index_icon_tag@3x.png")]}
                  />
                  <span>{val.work}</span>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
        <div className="entrance-fc">
          所以，开家相馆帮助他们吧!最主要的是还能为您带收益
        </div>
        <div
          className="entrance-fb act-btn"
          onClick={this.openCamera.bind(this)}
        >
          <span>我要开家线上相馆</span>
          <img
            alt=""
            className="open"
            src={[require("@/assets/btn_open@3x.png")]}
          />
        </div>
      </div>
    );
  }
}
