import React, { useState } from 'react';
import Fold from '@/components/fold'
import './ruleExplain.scss';
export default function RuleExplain({ taskList }) {
    const [isRuleListOpen, setIsRuleListOpen] = useState(true);
    const ruleList = [
        {
            Q: <div>1、什么是 [奖励金] ？</div>,
            A: <div>答：[奖励金]是一笔激励线上相馆主的额外奖励</div>
        }, {
            Q: <div>2、如何获得 [奖励金] ？</div>,
            A: <div>答：
               {taskList[0] && <div className="flex"> <span>[1]</span> <span>开店 {taskList[0].limit_days}日内完成{taskList[0].limit_order}单证件照订单，
                <br /> 一次性奖励 <span style={{ color: "#FA6400" }}>{taskList[0].bonus}</span> 元</span></div>}
                {taskList[1] && <div className="flex"><span>[2]</span> <span>开店{taskList[1].limit_days}日内完成{taskList[1].limit_order}单证件照订单，<br />一次性奖励<span style={{ color: "#FA6400" }}>{taskList[1].bonus}</span>元</span></div>}
                {taskList[2] && <div className="flex"><span>[3]</span> <span>开店{taskList[2].limit_days}日内完成{taskList[2].limit_order}单证件照订单，<br />一次性奖励<span style={{ color: "#FA6400" }}>{taskList[2].bonus}</span>元</span></div>}
                <div className="flex"><span>[奖励金]会在开店后{taskList[0] && `第${taskList[0].limit_days + 1}日`}{taskList[1] && `、第${taskList[1].limit_days + 1}日`}{taskList[2] && `、第${taskList[2].limit_days + 1}日`}核算，满足以上要求会立即发放至奖励金中心，可在7日后进行提现。</span></div>
            </div>,
            tips: <div style={{ padding: "10px 19px", border: "1px dashed #E4E6F1", borderRadius: '6px', marginTop: '15px' }}>
                注意：<br />
                若发放 [奖励金] 之后的7日内有人退款（证件照是定制化商品，非特殊情况不予退款），扣除退款订单之后的成交订单分别{taskList[0] && `≥ ${~~(taskList[0].limit_order - taskList[0].limit_order * 0.006)}单`}{taskList[1] && `，≥ ${~~(taskList[1].limit_order - taskList[1].limit_order * 0.006)}单`}{taskList[2] && ` ,≥ ${~~(taskList[2].limit_order - taskList[2].limit_order * 0.006)}单，`}不影响 [奖励金] 的提现，若订单数量低于该值，则视为未完成目标，[奖励金]不予提现。
            </div>
        }, {
            Q: <div>3、 [奖励金] 如何提现？</div>,
            A: <div>答： [奖励金] 一次性发放，您在奖励金中心可查看，结算周期为T+7，即今日获得奖励金，您在7日后可提现。例如您在开店30日内成交500单证件照订单，在第31日会发放500元 [奖励金] ，您在第38日即可提现。</div>
        }, {
            Q: <div>4、为什么我没有 [奖励金] ？</div>,
            A: <div>答：主要原因可能为：<br />
            --您在指定的日期内没有完成相应的目标订单<br />
            --您在指定日期内完成了目标订单，但在之后的七天内有大量退款， [奖励金] 无法提现</div>
        }
    ];
    return (
        <div className="ruleExplain conent">
            <div className="title">
                <span>规则说明</span>
                <span className="more" onClick={_ => setIsRuleListOpen(!isRuleListOpen)}>
                    <span>{isRuleListOpen ? "点击收起" : "点击查看"}</span>
                    <img className="icon " src={[require(isRuleListOpen ? "@/assets/award_icon_back2@2x.png" : "@/assets/award_icon_back@2x.png")]} alt="" />
                </span>
            </div>
            {taskList.length !== 0 && <Fold isOpenFold={isRuleListOpen}>
                <div className='explain'>
                    <div className="explain-QA">
                        {ruleList.map((item, idx) =>
                            <div key={idx} className="explain-QA-item">
                                <div className="explain-QA-Q">
                                    <div>
                                        <img className="icon" src={[require("@/assets/award_icon_question@2x.png")]} alt="" />
                                    </div>
                                    <div className="">
                                        {item.Q}
                                    </div>
                                </div>
                                <div className="explain-QA-A">
                                    <div>
                                        <img className="icon" src={[require("@/assets/award_icon_answer@2x.png")]} alt="" />
                                    </div>
                                    <div className="" style={{ flex: "1" }}>
                                        {item.A}
                                    </div>
                                </div>
                                {item.tips && <div className="explain-tips">
                                    {item.tips}
                                </div>}
                            </div>
                        )}
                    </div>
                </div>
            </Fold>}
        </div >
    )
}