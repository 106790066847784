import React from "react";
// import { Link } from "react-router-dom";
import { Link } from 'react-router-dom';
import "./index.scss"
export default function useStrategy() {
    return (
        <Link to={"/strategy"} className="strategy-link" style={{}}>
            <img src={[require("@/assets/index_tag_tutorial@2x.png")]} alt="" />
        </Link>
    )
}