import React from "react";
import { withRouter } from "react-router-dom";
class Share extends React.Component {
  state = {
    isWx: false,
    isAli: false
  };
  componentDidMount() {
    if (
      window["__wxjs_environment"] === "miniprogram" ||
      /microMessenger/i.test(window.navigator.userAgent)
    ) {
      this.setState({
        isWx: true
      });
      document.getElementById("wxShare").addEventListener(
        "touchmove",
        function(e) {
          // e.stopPropagation(); // 阻止默认的处理方式(阻止下拉滑动的效果)
          e.preventDefault();
        },
        { passive: false }
      ); // passive 参数不能省略，用来兼容ios和android
    }
    if (window.navigator.userAgent.indexOf("Alipay") > -1) {
      this.setState({
        isAli: true
      });
    }
  }
  close() {
    this.props.close(false);
  }
  share() {
    this.props.share();
  }
  goPay() {
    this.props.goPay();
  }
  savePoster() {
    window.mixSDK.savePoster(this.props.imgUrl);
  }
  render() {
    return (
      <div id="wxShare">
        {this.props.isShow && (
          <div>
            <div
              onClick={this.close.bind(this)}
              style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100vw",
                backgroundColor: "rgba(0,0,0,1)",
                opacity: "0.4",
                height: "100vh",
                zIndex: "2"
              }}
            ></div>
            <div
              className="share"
              style={{
                position: "fixed",
                bottom: "0px",
                width: "100vw",
                backgroundColor: "#F0F1F5",
                zIndex: "3",
                borderRadius: "20px 20px 0px 0px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "20px",
                  marginTop: "12px"
                }}
              >
                <img
                  onClick={this.close.bind(this)}
                  alt=""
                  style={{ width: "30px", height: "30px" }}
                  src={[require("@/assets/pop_icon_cancle@3x.png")]}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-30px"
                }}
              >
                <div>
                  <img
                    alt="分享"
                    style={{
                      width: "225px",
                      height: this.props.isPaper ? "338px" : "400px",
                      margin: "0 auto",
                      marginTop: "10px",
                      border: "5px solid #fff"
                    }}
                    src={this.props.imgUrl}
                  />
                </div>
              </div>
              {!this.props.isPaper && (
                <div
                  style={{
                    width: "235px",
                    margin: "0 auto",
                    marginTop: "8px",
                    border: "1px dashed rgba(224,227,239,1)",
                    backgroundColor: "#F8F9FF"
                  }}
                >
                  <div style={{ margin: "10px 16px 11px 11px" }}>
                    <div
                      style={{
                        fontSize: "11px",
                        color: "#848BB0",
                        lineHeight: "16px"
                      }}
                    >
                      分享文案：
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "#242E5F",
                        lineHeight: "18px"
                      }}
                    >
                      最美证件照，智能美颜、一键换正装，10秒搞定你所有的证件照
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  paddingBottom: "env(safe-area-inset-bottom)",
                  width: "100vw",
                  height: "66px",
                  backgroundColor: "#fff",
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {this.props.tabIndex !== 2 && this.state.isWx && (
                  <div style={{ color: "#a1a1a1", marginTop: "10px" }}>
                    长按图片即可分享和保存
                  </div>
                )}
                {this.props.tabIndex === 0 &&
                  !this.state.isWx &&
                  !this.state.isAli && (
                    <div
                      onClick={this.share.bind(this)}
                      className="act-btn"
                      style={{
                        height: "46px",
                        width: "315px",
                        backgroundColor: "#DE0073",
                        borderRadius: "23px",
                        marginTop: "10px",
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      立即分享给朋友
                    </div>
                  )}
                {this.props.tabIndex === 1 &&
                  !this.state.isWx &&
                  !this.state.isAli && (
                    <div
                      onClick={this.share.bind(this)}
                      className="act-btn"
                      style={{
                        height: "46px",
                        width: "315px",
                        backgroundColor: "#DE0073",
                        borderRadius: "23px",
                        marginTop: "10px",
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      立即分享到朋友圈
                    </div>
                  )}
                {this.props.tabIndex === 0 && this.state.isAli && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-around",
                      marginTop: "10px"
                    }}
                  >
                    <div
                      onClick={this.savePoster.bind(this)}
                      style={{
                        width: "169px",
                        height: "46px",
                        backgroundColor: "#242E5F",
                        borderRadius: "23px",
                        color: "#fff",
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      保存图片
                    </div>
                    <div
                      onClick={this.share.bind(this)}
                      style={{
                        width: "169px",
                        height: "46px",
                        backgroundColor: "#DE0073",
                        borderRadius: "23px",
                        color: "#fff",
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      立即分享
                    </div>
                  </div>
                )}
                {this.props.tabIndex === 1 && this.state.isAli && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-around",
                      marginTop: "10px"
                    }}
                  >
                    <div
                      onClick={this.savePoster.bind(this)}
                      style={{
                        width: "169px",
                        height: "46px",
                        backgroundColor: "#242E5F",
                        borderRadius: "23px",
                        color: "#fff",
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <img
                        alt=""
                        style={{ width: "16px", marginRight: "4px" }}
                        src={[require("@/assets/btn_poster_download@3x.png")]}
                      />
                      保存电子海报
                    </div>
                    <div
                      onClick={this.goPay.bind(this)}
                      style={{
                        width: "169px",
                        height: "46px",
                        backgroundColor: "#DE0073",
                        borderRadius: "23px",
                        color: "#fff",
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <img
                        alt=""
                        style={{ width: "16px", marginRight: "4px" }}
                        src={[require("@/assets/btn_poster_buy@3x.png")]}
                      />
                      购买纸质海报
                    </div>
                  </div>
                )}
                {this.props.tabIndex === 2 && (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-around",
                      marginTop: "10px"
                    }}
                  >
                    <div
                      onClick={this.savePoster.bind(this)}
                      style={{
                        width: "169px",
                        height: "46px",
                        backgroundColor: "#242E5F",
                        borderRadius: "23px",
                        color: "#fff",
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <img
                        alt=""
                        style={{ width: "16px", marginRight: "4px" }}
                        src={[require("@/assets/btn_poster_download@3x.png")]}
                      />
                      保存电子海报
                    </div>
                    <div
                      onClick={this.goPay.bind(this)}
                      style={{
                        width: "169px",
                        height: "46px",
                        backgroundColor: "#DE0073",
                        borderRadius: "23px",
                        color: "#fff",
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <img
                        alt=""
                        style={{ width: "16px", marginRight: "4px" }}
                        src={[require("@/assets/btn_poster_buy@3x.png")]}
                      />
                      购买纸质海报
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(Share);
