import React from "react";
import "./index.scss";
import { setTitle } from '@/utils/tools'
import orderService from "@/service/orderService";
import { PullToRefresh } from "antd-mobile";
import {getPhoneDao} from "@/utils/dao.js";
export default class Order extends React.Component {
  state = {
    arr: [],
    refreshing: false,
    down: true,
    height: document.documentElement.clientHeight
  };
  goDetail(orderId) {
    this.props.history.push(`/order/detail?orderId=${orderId}`);
  }
  componentWillMount() {
    setTitle("海报订单")
  }
  componentDidMount() {
    this.getOrderList();
  }
  getOrderList() {
    orderService.getOrderList({
      params: { phone: getPhoneDao() },
      onSuccess: ({ data }) => {
        console.log(data);
        this.setState({
          arr: data.result,
          refreshing: false
        });
      },
      onError: err => {
        this.setState({
          refreshing: false
        });
      }
    });
  }
  render() {
    return (
      <div className="order">
        <PullToRefresh
          damping={60}
          distanceToRefresh={30}
          ref={el => (this.ptr = el)}
          style={{
            height: this.state.height,
            overflow: "auto"
          }}
          indicator={this.state.down ? {} : { deactivate: "上拉可以刷新" }}
          direction={this.state.down ? "down" : "up"}
          refreshing={this.state.refreshing}
          onRefresh={() => {
            this.setState({ refreshing: true });
            setTimeout(() => {
              this.getOrderList();
            }, 1000);
          }}
        >
          <div className="order-contant">
            <div className="top-bank"></div>
            {this.state.arr.map((val, index) => (
              <div key={index} onClick={this.goDetail.bind(this, val.order_id)}>
                <div className="order-bank"></div>
                <div className="order-div">
                  <div className="head">
                    <div className="no">订单编号：{val.order_id}</div>
                    <div className="status">{val.state}</div>
                  </div>
                  <div className="dashed"></div>
                  <div className="content">
                    <div>
                      <img alt="" src={val.poster_url} />
                    </div>
                    <div className="right">
                      <div className="name">PP材质带背胶(40*60cm)</div>
                      <div className="time">{val.payment_time}</div>
                    </div>
                  </div>
                  <div className="dashed"></div>
                  <div className="foot">
                    <div className="num">共{val.number}件商品</div>
                    <div className="money">¥{val.price}</div>
                  </div>
                </div>
              </div>
            ))}
            <div className="order-bank"></div>
          </div>
        </PullToRefresh>
      </div>
    );
  }
}
