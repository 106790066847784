import store from '../store/store';
import action from '../store/action'
import baseService from '@/service/baseService'
import EventEmitter from './EventEmitter'
const event = new EventEmitter();
let subscription = null;
export const showErr = (msg, title) => {
  store.dispatch(action.setErr(true, msg, 'err', title))
}
export const showSuccess = (args) => {
  store.dispatch(action.setErr(true, args.msg, 'success', args.title))
  subscription = event.subscribe("THUNDER_ON_THE_SHOWSUCCESS", value => (args.success(value)));
}
export const clearErr = () => {
  store.dispatch(action.setErr(false))
  if (subscription) {
    event.emit("THUNDER_ON_THE_SHOWSUCCESS", 'success');
    subscription.unsubscribe();
  }
}
export const setPhone = (phone) => {
  store.dispatch(action.setPhone(phone))
}

function deviceReadyOne(cb_back) {
  var deviceReady = function () {
    document.removeEventListener("deviceready", deviceReady, false);
    if ('function' == typeof cb_back) {
      cb_back();
    }
  }
  document.addEventListener("deviceready", deviceReady, false);
}
/**
* 微信浏览器中设置对应页面的标题
* 解决：IOS微信浏览器中用document.title 设置标题无效
* 解决：IOS下设置title的问题
* */
export const setTitle = (title) => {
  // var body = document.getElementsByTagName('body')[0];
  document.title = title;
  let callback = function () {
    try {
      window.CD58UtilsPlugin.setTitle(title);
    } catch (e) { }
  }
  if (window.CD58UtilsPlugin) {
    callback();
  } else {
    deviceReadyOne(callback);
  }
  var iframe = document.createElement("iframe");
  iframe.setAttribute("src", "logo.png");
  iframe.setAttribute("style", "display:none");
  iframe.addEventListener('load', function () {
    setTimeout(function () {
      try {
        iframe.removeEventListener('load');
      } catch (err) { }
      document.body.removeChild(iframe);
    }, 0);
  });
  document.body.appendChild(iframe);
};

export const loadWx = () => {
  baseService.getJSSdk({
    method: 'post',
    params: {
      url: window.location.href
    },
    onSuccess: ({ data }) => {
      window.wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: 'wx9b5b58fb23d8cd58', // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.noncestr, // 必填，生成签名的随机串
        signature: data.signature,// 必填，签名
        jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
      });
      window.wx.ready(function () {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        console.log('wx is ready')
      });
    },
    onError: ({ response }) => {
      console.log(response)
    }
  })
}