import React from 'react'
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom'
class Main extends React.Component {
  state = {
    tarList: [
      {
        actImgUrl: [require('@/assets/tabbar_icon_poster_nomal@3x.png')],
        norImgUrl: [require('@/assets/tabbar_icon_poster@3x.png')],
        content: '宣传物料',
        jumpUrl: '/publicize',
        isC: false
      }, {
        actImgUrl: [require('@/assets/tabbar_icon_money_highlight@3x.png')],
        norImgUrl: [require('@/assets/tabbar_icon_money_nomal@3x.png')],
        content: '我的收入',
        jumpUrl: '/publicize/income',
        isC: false
      }, {
        actImgUrl: [require('@/assets/tabbar_icon_store_highlight@3x.png')],
        norImgUrl: [require('@/assets/tabbar_icon_store_nomal@3x.png')],
        content: '店铺信息',
        jumpUrl: '/publicize/shop',
        isC: false
      }
    ]
  }
  componentDidMount() {
    this.checkUrl()
  }
  checkUrl() {
    let list = this.state.tarList
    list.forEach((val, i) => {
      if (val.jumpUrl === this.props.location.pathname) {
        val.isC = true
      }
    })
    this.setState({
      tarList: list
    })
  }
  choiceTab(index) {
    let list = this.state.tarList
    list.forEach((val, i) => {
      val.isC = false
    })
    list[index].isC = true
    this.setState({
      tarList: list
    })
    // this.props.history.push(list[index].jumpUrl)
  }
  render() {
    return (
      <>
        {this.state.tarList.some(item => item.isC) && <div className="footBar" style={{ paddingBottom: 'env(safe-area-inset-bottom)', bottom: '0', position: 'fixed', width: '100vw', height: '60px', backgroundColor: '#242E5F', zIndex: '2', display: 'flex', alignItems: 'top', boxShadow: '0px -2px 16px 0px rgba(7,18,85,0.31)' }}>
          <div style={{ width: '100%', justifyContent: 'space-around', display: 'flex', marginTop: '10px' }}>
            {this.state.tarList.map((val, index) =>
              <Link to={val.jumpUrl || ''} key={index} replace>
                <div style={{ textAlign: 'center' }} onClick={this.choiceTab.bind(this, index)}>
                  <div>
                    {val.isC ? <img style={{ width: '22px' }} alt="" src={val.actImgUrl} /> : <img style={{ width: '22px' }} alt="" src={val.norImgUrl} />}
                  </div>
                  {val.isC ? <div style={{ fontSize: '10px', color: '#DE0073', marginTop: '4px' }}>
                    {val.content}
                  </div> : <div style={{ fontSize: '10px', color: '#FFFFFF', marginTop: '4px', opacity: '0.3' }}>
                      {val.content}
                    </div>}
                </div>
              </Link>
            )}
          </div>
        </div>}
      </>
    )
  }
}
export default withRouter(Main);