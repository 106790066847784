import React, { useEffect, useState, useRef } from 'react';
import './fold.scss';

export default function Fold(params) {
    const [styleOpt, setStyleOpt] = useState(),
        [height, setHeight] = useState(),
        wrapEl = useRef();
    useEffect(() => {
        setHeight(wrapEl.current.offsetHeight)
    }, [])
    useEffect(() => {
        let newStyle = params.isOpenFold ?
            {
                height: `${height}px`
            } :
            {
                height: '0px'
            };
        setStyleOpt(newStyle)
    }, [params.isOpenFold, height])
    return (
        <div ref={wrapEl} className="fold-wrap" style={styleOpt}>
            {params.children}
        </div >
    )
}