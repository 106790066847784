import React from "react";
import "./index.scss";
import { showSuccess } from "@/utils/tools";
import {getPhoneDao} from "@/utils/dao.js";
import incomeService from "@/service/incomeService";
const qs = require("query-string");
export default class Withdraw extends React.Component {
  state = {
    money: ""
  };
  componentDidMount() {
    let { money } = qs.parse(this.props.location.search);
    this.setState({
      money: money
    });
  }
  tixian() {
    //调用wx登录
    window.mixSDK.loginWx({
      success: res => {
        console.log(`监听成功回调${res}`);
        if (window.navigator.userAgent.indexOf("Alipay") > -1) {
          incomeService.getAliMoney({
            params: {
              user_id: res,
              phone: getPhoneDao()
            },
            onSuccess: ({ data }) => {
              console.log(data);
              showSuccess({
                msg: "提现已到账,请在支付宝中查看",
                title: "申请提现成功",
                success: v => {
                  this.props.history.goBack();
                }
              });
            }
          });
        } else {
          incomeService.getMoney({
            params: {
              code: res,
              phone: getPhoneDao()
            },
            onSuccess: ({ data }) => {
              console.log(data);
              showSuccess({
                msg: "提现已到账,请在微信中查看",
                title: "申请提现成功",
                success: v => {
                  this.props.history.goBack();
                }
              });
            }
          });
        }
      },
      fail: err => {
        console.log(`监听失败回调${err}`);
      }
    });

    // showSuccess('预计1-2个工作日将会到账','申请提现成功')
  }
  render() {
    return (
      <div className="withdraw">
        <div className="withdraw-bank"></div>
        {/* <div className="withdraw-account">
          <div className="label">提现到账户</div>
          <div className="value"><span className="name">刘敏源</span><span className="phone">136****7890</span></div>
        </div> */}
        <div className="withdraw-flc">
          <div className="withdraw-money">
            <div className="tip">提现金额</div>
            <div className="number">¥{this.state.money}</div>
          </div>
        </div>
        <div onClick={this.tixian.bind(this)} className="withdraw-btn act-btn">
          立即提现
        </div>
      </div>
    );
  }
}
