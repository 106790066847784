import React from "react";
import { withRouter } from "react-router-dom";
class ImgLoad extends React.Component {
  state = {
    imgArr: [
      [require("@/assets/image3.jpg")],
      [require("@/assets/image3.jpg")],
      [require("@/assets/image3.jpg")],
      [require("@/assets/image3.jpg")],
      [require("@/assets/image3.jpg")],
      [require("@/assets/image3.jpg")],
      [require("@/assets/image3.jpg")]
    ],
    isAli: false
  };
  componentDidMount() {
    if (window.navigator.userAgent.indexOf("Alipay") > -1) {
      this.setState({
        isAli: true
      });
    }
  }
  imgClick(index) {
    this.props.choiceImg(index);
  }
  goPay() {
    this.props.history.push("/order");
  }
  render() {
    return (
      <div
        className="ImgLoad"
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          marginTop: "8px"
        }}
      >
        <div
          style={{
            width: "346px",
            height: "calc(100vh - 162px)",
            maxHeight: "calc(100vh - 162px)",
            overflow: "auto"
          }}
        >
          {this.props.imgArr.map((val, index) => (
            <div
              onClick={this.imgClick.bind(this, val.id)}
              key={index}
              style={{
                float: "left",
                marginLeft: index % 3 !== 0 ? "8px" : "0px",
                marginTop: index >= 3 ? "8px" : "0px",
                position: index === 0 ? "relative" : ""
              }}
            >
              <img
                alt=""
                src={val.url}
                style={{ width: "110px", borderRadius: "2px" }}
              ></img>
              {index === 0 && this.props.isTip && (
                <div
                  style={{
                    position: "absolute",
                    left: "0px",
                    top: "0px",
                    width: "110px",
                    height: "195px",
                    backgroundColor: "rgba(0,0,0,0.51)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <img
                      alt=""
                      style={{ width: "29px" }}
                      src={[require("@/assets/posters_tutorial_finger@3x.png")]}
                    />
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#fff",
                        marginTop: "12px",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <div
                        style={{
                          marginRight: "6px",
                          width: "12px",
                          height: "1px",
                          backgroundColor: "#fff",
                          display: "inline-block"
                        }}
                      ></div>
                      点击分享
                      <div
                        style={{
                          marginLeft: "6px",
                          width: "12px",
                          height: "1px",
                          backgroundColor: "#fff",
                          display: "inline-block"
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          {this.props.tabIndex === 2 ||
            (this.props.tabIndex === 1 && this.state.isAli && (
              <div style={{ width: "100%", float: "left" }}>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#848BB0",
                    marginTop: "14px"
                  }}
                >
                  其他服务
                </div>
                <div
                  onClick={this.goPay.bind(this)}
                  style={{
                    marginTop: "14px",
                    display: "flex",
                    backgroundColor: "#F8F9FF",
                    borderRadius: "4px",
                    height: "68px",
                    border: "1px dashed rgba(224,227,239,1)"
                  }}
                >
                  <div
                    style={{
                      marginLeft: "20px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center"
                    }}
                  >
                    <img
                      alt=""
                      src={[require("@/assets/poster_icon_order@3x.png")]}
                      style={{ width: "35px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "16px"
                    }}
                  >
                    <div>
                      <div style={{ fontSize: "15px", color: "#242E5F" }}>
                        纸质海报订单
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#848BB0",
                          marginTop: "2px"
                        }}
                      >
                        点击查看详情
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: "148px",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <img
                      alt=""
                      src={[require("@/assets/poster_icon_more@3x.png")]}
                      style={{ width: "18px" }}
                    />
                  </div>
                </div>
              </div>
            ))}
          <div style={{ width: "100%", height: "40px", float: "left" }}></div>
        </div>
      </div>
    );
  }
}
export default withRouter(ImgLoad);
