import React from "react";
export default class Head extends React.Component {
  state = {
    arr:
      window.navigator.userAgent.indexOf("Alipay") > -1
        ? [
            {
              font: "分享",
              isC: true
            },
            {
              font: "纸质海报",
              isC: false
            }
          ]
        : [
            {
              font: "微信好友",
              isC: true
            },
            {
              font: "朋友圈",
              isC: false
            },
            {
              font: "纸质海报",
              isC: false
            }
          ]
  };
  choiceTab(index) {
    let arr = this.state.arr;
    arr.forEach(val => {
      val.isC = false;
    });
    arr[index].isC = true;
    this.setState({
      arr
    });
    this.props.choiceTab(index);
  }
  render() {
    return (
      <div
        className="Head"
        style={{
          height: "50px",
          display: "flex",
          width: "100vw",
          alignItems: "center"
        }}
      >
        {this.state.arr.map((val, index) => (
          <div
            onClick={this.choiceTab.bind(this, index)}
            key={index}
            style={{ marginLeft: index === 0 ? "14px" : "26px" }}
          >
            {val.isC ? (
              <div
                style={{
                  fontWeight: "500",
                  fontSize: "18px",
                  color: "#242E5F",
                  marginTop: "5px"
                }}
              >
                {val.font}
              </div>
            ) : (
              <div style={{ fontSize: "14px", color: "#848BB0" }}>
                {val.font}
              </div>
            )}
            {val.isC && (
              <div
                style={{
                  margin: "0 auto",
                  marginTop: "5px",
                  width: "24px",
                  height: "3px",
                  background: "#DE0073",
                  boxShadow: "0px 2px 4px 0px rgba(255,0,148,0.23)",
                  borderRadius: "2px"
                }}
              ></div>
            )}
          </div>
        ))}
      </div>
    );
  }
}
