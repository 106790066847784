import React, { useReducer, useState, useRef, useEffect } from 'react';
import Fold from '@/components/fold'
import { curry } from "@/utils/utils";
import './mission.scss';
// XXX: 在外边和在useReducer里边不一样？
const missionListReducer = (state, action) => {
    if (action instanceof Array) {
        return action
    }
    const handleAction = {
        changOpen: idx => {
            const newState = [...state];
            newState[idx].open = !state[idx].open
            return newState;
        }
    }
    const [[handleFn, param]] = Object.entries(action)
    return handleAction[handleFn](param)
}
export default function Mission({ taskList }) {
    const iconArr = ["assets/award_number_one@2x.png", 'assets/award_number_two@2x.png', 'assets/award_number_three@2x.png']
    const [missionList, dispatchMission] = useReducer(missionListReducer, []),
        [showTipsIdx, dispatchShowTipsIdx] = useState(null),
        [tipsImgLeft, setTipsImgLeft] = useState(),
        tipsImgEl = useRef([]);
    const showTipsFn = curry(function (idx, event) {
        event.stopPropagation();
        dispatchShowTipsIdx(idx);
        idx !== null && setTipsImgLeft(tipsImgEl.current[idx].offsetLeft - 1);
    })
    useEffect(() => {
        const defaultList = taskList.map(item => Object.assign({}, item, { open: (item.state.task_state === 1 || item.state.task_state === 0) }))
        dispatchMission(defaultList)
    }, [taskList])
    return (
        <div className='mission conent'>
            <div className='title'>奖励金额</div>
            {missionList.map((item, idx) => (
                <div key={idx} className="mission-item" onClick={_ => { dispatchMission({ changOpen: idx }) }}>
                    <div className="mission-item-main">
                        <img className="mission-item-icon" src={[require(`@/${iconArr[idx]}`)]} alt="" />
                        <div className='mission-item-conent'>{`${item.limit_days}日内完成${item.limit_order}单可获得`}</div>
                        <img alt=""
                            ref={el => (tipsImgEl.current[idx] = el)}
                            style={{ marginLeft: "4px", width: "13px", verticalAlign: "top", position: "relative", zIndex: "4" }}
                            onClick={showTipsFn(idx)}
                            src={[require("@/assets/money_icon_doubt@3x.png")]} />
                        {
                            (showTipsIdx === idx) &&
                            (<>
                                <div style={{ whiteSpace: "pre-line", borderRadius: "4px", position: "absolute", top: "calc(50% + 20px)", zIndex: 10, backgroundColor: "rgba(0,0,0,0.75)", fontSize: "12px", lineHeight: "20px", color: "#fff" }}
                                >
                                    <div style={{ position: "absolute", top: "-16px", left: tipsImgLeft + 'px', width: "0", height: "0", border: "8px solid transparent", borderBottom: "8px solid rgba(0,0,0,0.75)" }}></div>
                                    <div style={{ margin: "16px 12px" }}>完成该目标，开店第{item.limit_days + 8}日奖励金可提现 （注：{item.limit_order}单订单为支付订单，未支付订单和退款订单不计算在内）</div>
                                </div>
                                <div
                                    onClick={showTipsFn(null)}
                                    style={{ position: "fixed", top: "0", left: "0", width: "100vw", height: "100vh", zIndex: "3" }}></div></>)
                        }
                        <div className='mission-item-money'>
                            <span>￥</span>
                            {item.bonus}
                        </div>
                    </div>
                    {item.state && item.state.task_state !== 2 &&
                        <>
                            <div className="mission-item-isFinish">
                                <img src={[require(`@/assets/${item.state.task_state ? "award_tag_finished@2x.png" : "award_tag_nofinished@2x.png"}`)]} alt="" />
                            </div>
                            {
                                (item.state.task_state === 1 || item.state.task_state === 0) &&
                                <Fold isOpenFold={item.open}>
                                    <div className="mission-item-info">
                                        <div className="item">
                                            <img className="icon" src={[require('@/assets/award_icon_complete@2x.png')]} alt="" />
                                            <span>累计完成{item.state.finish_order}单</span>
                                        </div>
                                        {item.state.task_state === 0 && <div className="item">
                                            <img className="icon" src={[require('@/assets/award_icon_unfinished@2x.png')]} alt="" />
                                            <span>还差 {item.state.rest_order}单</span>
                                        </div>}
                                    </div>
                                </Fold>
                            }
                        </>
                    }
                </div>
            ))
            }
        </div >
    )

}