import c2s from "@/utils/http";

const publicizeService = {
  previewImgs(option) {
    return c2s(
      {
        url: `/api/images/preview`,
        ...option
      },
      { mask: true, silentError: true }
    );
  },
  shareImg(option) {
    let url = "";
    if (window.navigator.userAgent.indexOf("Alipay") > -1) {
      url = "/api/alipay/poster";
    } else {
      url = "/api/poster";
    }
    return c2s(
      {
        method: "post",
        url: url,
        ...option
      },
      { mask: true, silentError: true }
    );
  }
};

export default publicizeService;
