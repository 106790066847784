import React from 'react'
import Loadable from "react-loadable"

let config = [
  {
    name: '/',
    path: '/',
    exact: true,
    title: 'sss',
    component: Loadable({
      loader: () => import('@/views/entrance'),
      loading: () => <div />
    })
  }, {
    name: 'prepare',
    path: '/prepare',
    exact: true,
    title: 'ddd',
    component: Loadable({
      loader: () => import('@/views/prepare'),
      loading: () => <div />
    })
  }, {
    name: 'login',
    path: '/login',
    exact: true,
    component: Loadable({
      loader: () => import('@/views/login'),
      loading: () => <div />
    })
  }, {
    name: 'publicize',
    path: '/publicize',
    exact: false,
    component: Loadable({
      loader: () => import('@/views/main'),
      loading: () => <div />
    })
  }, {
    name: 'publicize',
    path: '/publicize',
    exact: true,
    component: Loadable({
      loader: () => import('@/views/main/publicize'),
      loading: () => <div />
    })
  }, {
    name: 'income',
    path: '/publicize/income',
    exact: true,
    component: Loadable({
      loader: () => import('@/views/main/income'),
      loading: () => <div />
    })
  }, {
    name: 'withdrawRecord',
    path: '/publicize/income/withdrawRecord',
    exact: true,
    component: Loadable({
      loader: () => import('@/views/main/income/withdrawRecord'),
      loading: () => <div />
    })
  }, {
    name: 'bounty',
    path: '/publicize/income/bounty',
    exact: true,
    component: Loadable({
      loader: () => import('@/views/main/income/bounty'),
      loading: () => <div />
    })
  }, {
    name: 'strategy',
    path: '/strategy',
    exact: true,
    component: Loadable({
      loader: () => import('@/views/main/strategy'),
      loading: () => <div />
    })
  }
]

export default config