import React from "react";
import "./index.scss"
import ClipboardJS from "clipboard";
import { Toast } from "antd-mobile";
const wx = "zjzchongyin";
const wxQrcodeImgSrc = 'https://photostudio.oss-cn-shanghai.aliyuncs.com/photostudio_assistant.png';
const downloadIamge = (imgsrc) => {
    window.mixSDK.savePoster(imgsrc);
    window.mixSDK.posterCallBack = function (res) {
        res ? Toast.info(toastSuccessJSX("下载成功！")) : Toast.fail("下载成功！");

    }
};
const toastSuccessJSX = (text) => {
    return (
        <div>
            <img style={{ width: "50px", height: "50px" }} src={[require("@/assets/pop_icon_complete@2x.png")]} alt="" />
            <div>{text}</div>
        </div>
    )
};

export default function ({ onClose }) {
    let clipboard = new ClipboardJS("#copy");
    clipboard.on('success', function (e) {
        Toast.info(toastSuccessJSX("复制成功！"))
    });
    clipboard.on('error', function (e) {
        Toast.fail("复制失败！")
    });
    return (
        <div className="pop-wrap mark-wrap" style={{ padding: "0 35px" }}>
            <div className="conent" style={{ padding: "15px 15px 20px" }}>
                <div className="close">
                    <img style={{ width: '14px', height: "15px" }} src={[require("@/assets/pop_icon_cancle@2x.png")]} alt="" onClick={_ => { onClose() }} />
                </div>
                <div className="info" style={{ margin: "0 27px 17px" }}>
                    <img src={wxQrcodeImgSrc} style={{ width: "40px", height: "40px" }} alt="" />

                    <div style={{ marginLeft: "12px" }}>
                        <div className="info-title" style={{ margin: "4px 0" }}>线上相馆助手</div>
                        <div className="tips" style={{ fontSize: "13px" }}>微信：{wx}
                            {/* <input type="text" id="copy" readOnly value={wx} style={{ visibility: "none" }} /> */}
                            <span className="copy-btn"
                                id="copy"
                                data-clipboard-text={wx}
                                // onClick={_ => { copy() }}
                                style={{
                                    width: "36px",
                                    height: "18px",
                                    lineHeight: "18px",
                                    marginLeft: "6px",
                                    borderRadius: "2px"
                                }}>复制</span>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: "center" }}>
                    <img style={{ width: "210px", margin: "0 auto", boxShadow: "0 10px 30px #E4E4E4" }} src={[require("@/assets/pop_wechat_qrcode@2x.png")]} alt="" />
                </div>
                <div className="tip" style={{ margin: "8px auto 18px", fontSize: "12px" }}>微信扫一扫添加</div>
                <div onClick={_ => {
                    downloadIamge(wxQrcodeImgSrc, "线上相馆二维码")
                }} className="save-btn" style={{ borderRadius: "24px", width: "260px", height: "46px", lineHeight: "46px", fontSize: "16px" }}>保存图片</div>
            </div>
        </div>
    )
}