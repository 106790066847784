
import React from 'react'
export default class Login extends React.Component {
  state = {
    // content: this.props.content,
    title: this.props.title || "温馨提示",
    cancelText: this.props.cancelText || "取消",
    okText: this.props.okText || "确认",
    className: this.props.className || ""
  }
  close(bol) {
    this.props.closeConfirm(bol)
  }
  render() {
    return (
      <div className={`confirm ${this.state.className}`} >
        {this.props.showConfirm &&
          <div>
            <div style={{ height: '100vh', width: '100vw', backgroundColor: 'rgba(0,0,0,1)', opacity: '0.4', position: 'fixed', left: '0', top: '0', zIndex: '10' }}></div>
            <div style={{ position: 'fixed', top: '200px', width: '100vw', display: 'flex', justifyContent: 'center', zIndex: '10' }}>
              <div style={{ width: '305px', backgroundColor: '#fff', borderRadius: '4px', textAlign: 'center' }}>
                <div style={{ color: "#242E5F", fontWeight: "600", fontSize: '20px', margin: '0 auto', marginTop: '20px' }}>{this.state.title}</div>
                <div style={{ width: '260px', borderRadius: '2px', margin: '0 auto', marginTop: '20px' }}>
                  {this.props.children}
                </div>
                <div style={{ width: '100%', marginTop: '22px', display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                  <div className="act-btn" onClick={this.close.bind(this, false)} style={{ marginLeft: '18px', color: '#262F60', width: '132px', height: '46px', backgroundColor: '#F0F1F5', borderRadius: '23px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{this.state.cancelText}</div>
                  <div className="act-btn" onClick={this.close.bind(this, true)} style={{ marginRight: '18px', color: '#fff', width: '132px', height: '46px', backgroundColor: '#DE0073', borderRadius: '23px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{this.state.okText}</div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}