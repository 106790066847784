import React from 'react'
import { Toast } from 'antd-mobile';

import Confirm from '@/components/confirm'
import ClassCropperModal from './components/ClassCropperModal'
import UseStrategy from "../components/useStrategy";
import { showErr, setTitle } from '@/utils/tools'
import { isNA } from '@/utils/utils'
import QrcodePop from "@/isWxMiniPro/components/qrcodePop";
import {getPhoneDao,removePhoneDao} from "@/utils/dao.js";
import shopService from '@/service/shopService'
import './index.scss'
const MAX_FILE_SIZE = 5 * 1024 * 1024 // 文件最大限制为5M
const isWxMiniPro = window["__wxjs_environment"] === "miniprogram" || isNA(/microMessenger/);
export default class Shop extends React.Component {
  state = {
    content: '1、选择关闭店铺，所有的店铺信息将不能再次查看，所有的未提现现金额将不能再提现!\n2、如果要更换账号,请先关闭店铺',
    name: '',
    showCloseShop: false,
    showChangeName: false,
    selectedImageFile: null,
    editImageModalVisible: false,
    isDisplayWXPop: false,
    phone: '',
    userInfo: {}
  }
  componentWillMount() {
    setTitle('店铺信息');
  }
  componentDidMount() {
    this.setState({
      phone: getPhoneDao()
    }, () => {
      this.getUserInfo()
    })
  }
  getUserInfo() {
    shopService.userInfo({
      params: { phone: this.state.phone },
      onSuccess: ({ data }) => {
        console.log(data)
        this.setState({
          userInfo: data
        })
      }
    })
  }
  isChangeName(bol) {
    this.hidePop()
    if (bol) {
      this.state.name ? (shopService.changeShopName({
        params: {
          phone: this.state.phone,
          shop_name: this.state.name
        },
        onSuccess: ({ data: { result } }) => {
          let { userInfo } = this.state;
          userInfo.shop_name = this.state.name;
          Toast.success(result, 2)
          this.setState({
            userInfo,
            name: ''
          })
        }
      })) : Toast.fail('请输入修改名称', 2)
    }

    this.setState({
      showChangeName: false
    })
  }
  isCloseShop(bol) {
    if (!bol) {
      //关闭店铺
      shopService.closeShop({
        params: { phone: this.state.phone },
        onSuccess: ({ data }) => {
          removePhoneDao();
          Toast.success(data.result, 1, _ => {
            window.mixSDK.backNative()
          })
        }
      })
    }
    this.setState({
      showCloseShop: false
    })
  }
  closeShop() {
    this.setState({
      showCloseShop: true
    })
  }
  changeShopName() {
    this.showPop()
    this.setState({
      showChangeName: true
    })
  }
  spanClick(event) {
    event.stopPropagation();
    var link = document.getElementById('file');
    link.click();
  }
  handleFileChange(e) {
    const file = e.target.files[0]
    console.log(file)
    if (file) {
      if (file.size <= MAX_FILE_SIZE) {
        this.setState({
          selectedImageFile: file,
        }, () => {
          this.setState({
            editImageModalVisible: true,
          })
        })
      } else {
        console.log('文件过大')
        showErr('文件过大')
      }
    }

    e.target.value = ''
  }
  handleGetResultImgUrl(blob) {
    console.log(blob)
    const formData = new FormData()
    // 添加要上传的文件
    formData.append('file', blob)
    formData.append('phone', this.state.phone)
    shopService.uploadHead({
      params: formData,
      onSuccess: ({ data }) => {
        this.getUserInfo()
      }
    })
  }
  showPop() {
    document.body.addEventListener("touchmove", this.stopScroll, { passive: false });
    document.body.style.overflow = 'hidden';
  }
  hidePop() {
    document.body.removeEventListener('touchmove', this.stopScroll);
    document.body.removeEventListener('touchmove', this.stopScroll, { passive: true });
    document.body.style.overflow = 'auto';
  }
  stopScroll(e) {
    e.stopPropagation();
    e.preventDefault();
  }
  wxCode() {
    this.setState({
      isDisplayWXPop: true
    }, _ => {
      console.log(this.state.isDisplayWXPop)
    })

  }

  render() {
    let backStyle = {
      backgroundImage: `url(${this.state.userInfo.avatar})`,
      backgroundSize: '100% 100%'
    }
    return (
      <div className="shop">
        <div className="shop-head">
          <div>
            <div className="shop-circle" style={backStyle}>
              {/* <div className="img-update">
                修改
                <input
                  id="file"
                  type="file"
                  accept="image/jpeg,image/jpg,image/png"
                  className="base-upload-input"
                  onChange={this.handleFileChange.bind(this)}
                />
              </div> */}
            </div>
          </div>
          <div className="shop-right">
            <div className="shop-name">{this.state.userInfo.shop_name}</div>
            <div className="shop-c" onClick={this.closeShop.bind(this)}><img alt='' className="shop-close" src={[require('@/assets/store_icon_shutdown@3x.png')]} />关闭店铺</div>
          </div>
        </div>
        <img className="store-img" alt="" src={[require("@/assets/store_bg_wenli@3x.png")]} />
        <div className="shop-item">
          <div className="shop-cell">
            <div>
              <img alt='' className="cell-img" src={[require('@/assets/store_icon_time@3x.png')]} />
            </div>
            <div className="cell-font">
              <div className="cell-font-fir">开店时间</div>
              <div className="cell-font-sec">{this.state.userInfo.create_time} </div>
            </div>
          </div>
        </div>
        <div className="shop-item">
          <div className="shop-cell">
            <div>
              <img alt='' className="cell-img" src={[require('@/assets/store_icon_phone@3x.png')]} />
            </div>
            <div className="cell-font">
              <div className="cell-font-fir">注册手机号</div>
              <div className="cell-font-sec">{this.state.userInfo.phone}</div>
            </div>
          </div>
        </div>
        <div className="shop-item" onClick={this.changeShopName.bind(this)}>
          <div className="shop-cell">
            <div>
              <img alt='' className="cell-img" src={[require('@/assets/store_icon_edit@3x.png')]} />
            </div>
            <div className="cell-font">
              <div className="cell-font-fir">修改名称</div>
              <div className="cell-font-sec">点击可修改店铺名称</div>
            </div>
            <div className="cell-icon-right">
              <img alt='' className="cell-icon-img" src={[require('@/assets/poster_icon_right@3x.png')]} />
            </div>
          </div>
        </div>
        {isWxMiniPro && <div className="shop-item" onClick={this.wxCode.bind(this)}>
          <div className="shop-cell">
            <div>
              <img alt='' className="cell-img" src={[require('@/assets/store_icon_edit@3x.png')]} />
            </div>
            <div className="cell-font">
              <div className="cell-font-fir">相馆推广咨询</div>
              <div className="cell-font-sec">添加微信：zjzchongyin</div>
            </div>
            <div className="cell-icon-right">
              <img alt='' className="cell-icon-img" src={[require('@/assets/poster_icon_right@3x.png')]} />
            </div>
          </div>
        </div>}
        {isWxMiniPro && this.state.isDisplayWXPop && <QrcodePop onClose={_ => { this.setState({ isDisplayWXPop: false }) }} />}
        <Confirm closeConfirm={bol => this.isCloseShop(bol)} showConfirm={this.state.showCloseShop} cancelText="确认关店" okText="继续营业" >
          <div style={{ backgroundColor: '#F8F9FF', padding: '20px 22px', fontSize: '14px', color: '#242E5F', whiteSpace: 'pre-line', textAlign: 'left', border: '1px dashed rgba(224,227,239,1)' }}>
            {this.state.content}
          </div>
        </Confirm>
        <Confirm title="修改店铺名称" closeConfirm={bol => this.isChangeName(bol)} showConfirm={this.state.showChangeName} okText="确认修改" >
          <div className="line"></div>
          <div className="changeName">
            <div className="changeName-input" style={{ width: "260px" }}>
              <div className="input-bank">
                <img className="input-img" style={{ width: "21px", margin: "13px 6px 13px 13px", }} alt="" src={[require("@/assets/login_icon_write@3x.png")]} />
              </div>
              <div className="input-wb">
                <input style={{ fontSize: "14px" }} value={this.state.name} maxLength={10} onChange={(e) => this.setState({ name: e.target.value })}
                  className="input-ip" placeholder='请输入修改名称（不超过10个字）' />
              </div>
            </div>
          </div>
        </Confirm>
        <UseStrategy />
        {this.state.editImageModalVisible && (
          <ClassCropperModal
            uploadedImageFile={this.state.selectedImageFile}
            onClose={() => {
              this.setState({ editImageModalVisible: false })
            }}
            onSubmit={this.handleGetResultImgUrl.bind(this)}
          />
        )}
      </div>
    )
  }
}