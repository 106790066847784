import React from 'react';
import {getPhoneDao} from "@/utils/dao.js";
import './index.scss'
import incomeService from '@/service/incomeService'
export default class Total extends React.Component {
  state = {
    monthDetail: {},
    yearDetail: {},
  }
  componentDidMount() {
    incomeService.getAnalysis({
      params: { phone: getPhoneDao() },
      onSuccess: ({ data }) => {
        data.month_detail.month_income = data.month_detail.month_income.toFixed(2);
        data.year_detail.year_income = data.year_detail.year_income.toFixed(2)
        this.setState({
          monthDetail: data.month_detail,
          yearDetail: data.year_detail
        })
      }
    })
  }
  render() {
    return (
      <div className="total">
        <div className="total-flc">
          <div className="total-div">
            <div className="tip"><div className="red"></div><div>月收入({this.state.monthDetail.now_month})</div></div>
            <div className="money"><span>¥</span>{this.state.monthDetail.month_income}</div>
          </div>
        </div>
        <div className="total-flc">
          <div className="total-div">
            <div className="tip"><div className="red"></div><div>年收入({this.state.yearDetail.now_year}）</div></div>
            <div className="money"><span>¥</span>{this.state.yearDetail.year_income}</div>
          </div>
        </div>
      </div>
    )
  }
}