import React from "react";
import "./index.scss";
import store from "@/store/store";
// import {loadWx} from '@/utils/tools'
import { Toast } from "antd-mobile";
import orderService from "@/service/orderService";
import {getPhoneDao} from "@/utils/dao.js";
const qs = require("query-string");
export default class Pay extends React.Component {
  state = {
    showAddr: false,
    num: 1,
    singlePrice: 29.9,
    total: "29.9",
    addrInfo: null,
    code: "",
    postId: 0,
    postUrl: ""
  };
  componentDidMount() {
    let { postId } = qs.parse(this.props.location.search);
    this.setState({
      postId: postId,
      postUrl: store.getState().postUrl
    });
    //查看缓存中是否有地址信息
    this.checkAddr();
  }
  checkAddr() {
    // let addr = window.localStorage.getItem('lq-addr')
    // console.log(addr)
    let addr = JSON.parse(window.localStorage.getItem("lq-pocket-addr"));
    if (!addr) {
      return;
    }
    this.setState({
      showAddr: true,
      addrInfo: addr
    });
  }
  toAddr() {
    this.props.history.push("/order/address");
  }
  pay() {
    if (!this.state.showAddr) {
      Toast.info("请添加地址信息!", 1);
      return;
    }
    //授权
    orderService.createOrder({
      params: {
        phone: getPhoneDao(),
        consignee_name: this.state.addrInfo.name,
        consignee_addr:
          this.state.addrInfo.addressValue + this.state.addrInfo.detail,
        consignee_phone: this.state.addrInfo.phone,
        poster_id: this.state.postId,
        number: this.state.num
      },
      onSuccess: ({ data }) => {
        //TODO创建完订单要判断是APP支付还是小程序支付
        if (
          window["__wxjs_environment"] === "miniprogram" ||
          /microMessenger/i.test(window.navigator.userAgent)
        ) {
          //小程序支付
          orderService.getWxMiniPayInfo({
            params: {
              openid: window.localStorage.getItem("lq-pocket-uniqueId"),
              order_id: data.order_id,
              phone: getPhoneDao()
            },
            onSuccess: ({ data }) => {
              window.mixSDK.askWechatRequest({ ...data.result }, res => {});
            }
          });
        } else if (window.navigator.userAgent.indexOf("Alipay") > -1) {
          //支付宝支付
          orderService.getAliPayInfo({
            params: {
              user_id: window.localStorage.getItem("lq-pocket-uniqueId"),
              order_no: data.order_id,
              phone: getPhoneDao()
            },
            onSuccess: ({ data }) => {
              window.mixSDK.askWechatRequest(data.result, res => {
                this.props.history.push("/order");
              });
            }
          });
        } else {
          //APP支付
          orderService.getPayInfo({
            params: {
              order_id: data.order_id,
              phone: getPhoneDao()
            },
            onSuccess: ({ data }) => {
              window.mixSDK.askWechatRequest({ ...data.result }, res => {
                console.log(res);
                if (res.value === "paySuccess") {
                  //支付成功
                  this.props.history.push("/order");
                }
              });
            }
          });
        }
      }
    });
  }
  count(type) {
    let num = this.state.num;
    if (type === "add") {
      if (num === 20) {
        Toast.info("不能再加了哦!", 1);
        return;
      }
      num = num + 1;
      this.setState({
        num: num,
        total: this.accMul(num, this.state.singlePrice)
      });
    } else {
      if (num === 1) {
        Toast.info("不能再减了哦!", 1);
        return;
      }
      num = num - 1;
      this.setState({
        num: num,
        total: this.accMul(num, this.state.singlePrice)
      });
    }
  }
  accMul(arg1, arg2) {
    var m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString();
    try {
      m += s1.split(".")[1].length;
    } catch (e) {}
    try {
      m += s2.split(".")[1].length;
    } catch (e) {}
    return (
      (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
      Math.pow(10, m)
    );
  }
  render() {
    return (
      <div className="pay">
        <div className="flc">
          <img alt="" src={this.state.postUrl} />
        </div>
        <div className="detail-div">
          <div className="row">
            <div className="label">尺寸</div>
            <div className="value">40 × 60 cm</div>
          </div>
          <div className="row">
            <div className="label">材质</div>
            <div className="value">PP材质带背胶</div>
          </div>
          <div className="row">
            <div className="label">购买数量</div>
            <div className="value">
              <img
                onClick={this.count.bind(this, "reduce")}
                alt=""
                src={[require("@/assets/order_number_less@3x.png")]}
              />
              <span className="num">{this.state.num}</span>
              <img
                onClick={this.count.bind(this, "add")}
                alt=""
                src={[require("@/assets/order_number_add@3x.png")]}
              />
            </div>
          </div>
        </div>
        <div className="bank"></div>
        <div className="detail-address" onClick={this.toAddr.bind(this)}>
          {!this.state.showAddr ? (
            <div className="add-add">
              <img alt="" src={[require("@/assets/order_adress_add@3x.png")]} />
              添加收货地址
            </div>
          ) : (
            <div className="adress-info">
              <div className="info-left">
                <div className="name">
                  {this.state.addrInfo.name}{" "}
                  <span>{this.state.addrInfo.phone}</span>
                </div>
                <div className="detail">
                  {this.state.addrInfo.addressValue +
                    this.state.addrInfo.detail}
                </div>
              </div>
              <div className="info-right">
                <img
                  alt=""
                  src={[require("@/assets/poster_icon_more@3x.png")]}
                />
              </div>
            </div>
          )}
        </div>
        <div className="bank"></div>
        <div className="detail-td">
          <div className="tip">
            发货说明：每日16:00前的订单当日安排制作，16:00的订单次日安排制作，制作之后2个工作日内发出，周末和节假日期间不制作，放假结束后开始安排制作，敬请谅解~
          </div>
        </div>
        <div className="foot">
          <div className="foot-div">
            <div className="foot-left">
              <div className="money">共计¥ {this.state.total}</div>
              <div className="lable">(含设计+快递+纸质海报)</div>
            </div>
            <div className="foot-right">
              <div className="btn" onClick={this.pay.bind(this)}>
                <img
                  alt=""
                  style={{ width: "16px", marginRight: "4px" }}
                  src={[require("@/assets/btn_poster_buy@3x.png")]}
                />
                立即购买
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
