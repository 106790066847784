import React from 'react'
import { Toast } from 'antd-mobile';
import './index.scss'
import baseService from '@/service/baseService'
import { setTitle } from '@/utils/tools'
const qs = require('query-string');
export default class Partake extends React.Component {
  state = {
    url: [require('@/assets/bg@3x.png')],
    iosFlag: false,
    miniUrl: '',
    appName: ''
  }
  componentWillMount() {
    // document.title = '下载页面'
    setTitle('下载页面')
  }
  componentDidMount() {
    //判断手机系统
    this.checkSys()

    // this.inintIOS()
  }
  inintIOS(key) {
    var data = window.OpenInstall.parseUrlParams();
    new window.OpenInstall({
      appKey: key,
      // preferWakeup:true,
      onready: function () {
        var m = this, button = document.getElementById("downloadButton");
        m.schemeWakeup();
        button.onclick = function () {
          Toast.loading('Loading...', 1, () => {
            console.log('Load complete !!!');
          });
          m.wakeupOrInstall();
          return false;
        }
      }
    }, data);
  }
  checkSys() {
    if (!!window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      this.setState({
        iosFlag: true
      })
      this.qrcode(1)
      // this.qrcode(0)
    } else {
      this.setState({
        iosFlag: false
      })
      this.qrcode(0)
    }
  }
  qrcode(type) {
    let { invite_code } = qs.parse(this.props.location.search)
    baseService.qrcode({
      params: { invite_code: invite_code, phone_type: type },
      onSuccess: ({ data }) => {
        if (type === 0) {
          this.setState({
            miniUrl: data.url,
            appName: data.app_name
          })
        } else {
          this.setState({
            appName: data.app_name
          })
          if(data.app_name === '智能证件照'){
            this.setState({
              miniUrl: data.url
            })
          }else{
            this.inintIOS(data.key)
          }
        }
      }
    })
  }
  render() {
    return (
      <div className="Partake" style={{ backgroundImage: `url(${this.state.url})` }}>
        <div className="head">
          <div className="bank"></div>
          <div className="t1">THE MOST BEAUTIFUL</div>
          <div className="t2">{this.state.appName}</div>
          <div className="gray"></div>
          <div className="t3">智/能/美/颜 、一/键/换/正/装</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img className="imga" alt="" src={[require("@/assets/bg-g@3x.png")]} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="foot">
            <div className="foot-left">
              <div className="p1"><span>10s</span>搞定你所有的证件照</div>
              <div className="p2"><div className="p3">{!this.state.iosFlag || this.state.appName==='智能证件照' ? '长按识别二维码' : '点击右侧按钮下载'}<img className="img" alt="" src={[require("@/assets/bg@3x(1).png")]} /></div></div>
            </div>
            <div className="foot-right">
              {!this.state.iosFlag || this.state.appName === '智能证件照' ? <img className="img" alt="" src={this.state.miniUrl} /> : <div id="downloadButton" className="btn act-btn">立即下载</div>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}