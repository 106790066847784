import c2s from "@/utils/http";

const orderService = {
  createOrder(option) {
    return c2s(
      {
        method: "post",
        url: `/api/poster/order`,
        ...option
      },
      { mask: true, silentError: true }
    );
  },
  getPayInfo(option) {
    return c2s(
      {
        method: "post",
        url: `/api/poster/app_params`,
        ...option
      },
      { mask: true, silentError: true }
    );
  },
  getWxMiniPayInfo(option) {
    return c2s(
      {
        method: "post",
        url: `/api/poster/applet_params`,
        ...option
      },
      { mask: true, silentError: true }
    );
  },
  getAliPayInfo(option) {
    return c2s(
      {
        method: "post",
        url: `/api/poster/alipay_trade`,
        ...option
      },
      { mask: true, silentError: true }
    );
  },
  getOrderList(option) {
    return c2s(
      {
        url: `/api/poster/orders`,
        ...option
      },
      { mask: true, silentError: true }
    );
  },
  getOrderDetail(option) {
    return c2s(
      {
        url: `/api/poster/details`,
        ...option
      },
      { mask: true, silentError: true }
    );
  }
};

export default orderService;
