import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk';
import reducer from './reducer'
const initValue = {
  'errFlag': false,
  'errMsg': '',
  'icon': 'err',
  'title': '温馨提示',
  'phone': '',
  'autologin': true,
  'postUrl': '',
  'isShowedNewSchemeToast': false
}
const store = createStore(reducer, initValue, applyMiddleware(thunk))
export default store