import React, { useState, useEffect } from "react";
import './process.scss';

export default function Process({
    bonus,
    state: {
        finish_order,
        rest_order
    } = {}
}) {
    const [percentage, setPercentage] = useState(finish_order / (finish_order + rest_order) * 100);
    const [popoverStyle, setPopoverStyle] = useState({ left: 0 })
    useEffect(() => {
        setPercentage(finish_order / (finish_order + rest_order) * 100);
    }, [finish_order, rest_order])
    useEffect(() => {
        let style = {};
        percentage >= 20 && percentage <= 65 && (style = {
            left: `${percentage}%`
        });
        percentage < 20 && (style = {
            left: `1.6vw`
        });
        percentage > 65 && (style = {
            right: `1.6vw`
        });
        setPopoverStyle(style)
    }, [percentage])
    return (
        <div className="process">
            <div className="process-slider">
                <div className="slider-wrap">
                    <div className="slider-step" style={{ width: `${percentage}%` }}>
                        <div className="slider-popover" style={popoverStyle}>已完成{finish_order}单</div>
                    </div>
                </div>
                <div className="gift-wrap">
                    <img className="gift" src={[require("@/assets/award_box_image@2x.png")]} alt="" />
                </div>

            </div>
            <div className="process-dec">
                <span>还差{rest_order}单领取奖励金</span>
                <span className="process-money"><span>￥</span>{bonus}</span>
            </div>
        </div>
    )
}