import React, { useState, useEffect, useReducer } from 'react'
import {getPhoneDao} from "@/utils/dao.js";
// import { PullToRefresh } from 'antd-mobile';
import PullToRefresh from "@/components/pullToRefresh";
import incomeService from '@/service/incomeService';
import './record.scss';
const tip = `1、邀请用户支付电子证件照订单，你将获得该笔订单收入的30%作为佣金；邀请用户支付冲印订单每支付1单，你将获得2元佣金；\n2、未成交（待退款和已退款）的订单，你将不会获得佣金；`
const recordType = {
  0: {
    name: "电子照",
    icon: 'assets/order_icon_image@2x.png'
  },
  1: {
    name: "冲印",
    icon: 'assets/order_icon_image2@2x.png'
  },
  null: {
    name: "",
    icon: ""
  }
}
const defaultDealObj = () => {
  return {
    page: 1,
    order_num: 0,
    list: []
  }
}

const defaultDealReducer = (state, { order_num, resultList }) => {
  ([...state.list, ...resultList]).length < order_num && state.page++;
  state.order_num = order_num;
  state.list = [...state.list, ...resultList].length <= order_num ? [...state.list, ...resultList] : state.list;
  return state;
};
const dealReducer = defaultDealReducer;
const noDealReducer = defaultDealReducer;

export default function (params) {
  const [choiceFlag, setChoiceFlag] = useState(true),
    [visible, setVisible] = useState(false),
    [refreshing, setRefreshing] = useState(false);
  const [deal, dispatchDeal] = useReducer(dealReducer, undefined, defaultDealObj);
  const [noDeal, dispatchNoDeal] = useReducer(noDealReducer, undefined, defaultDealObj);
  const [height, setHeight] = useState();
  // const down = false;
  const closeTip = (bol, event) => {
    event.stopPropagation();
    setVisible(bol)
  }
  const getRecordList = ({
    phone = getPhoneDao(),
    state = 1,
    page = 1,
    page_size = 5
  }) => {
    return incomeService.getRecordList({
      params: {
        phone,
        state,
        page,
        page_size
      }
    }).then(({ data: {
      result: resultList,
      order_num
    } }) => {
      state ? dispatchDeal({ order_num, resultList }) : dispatchNoDeal({ order_num, resultList });
      setRefreshing(false);
      return resultList
    });
  };

  useEffect(() => {
    getRecordList({ state: 0 });
    getRecordList({ state: 1 });
    setHeight(document.documentElement.clientHeight - 216 - 16 - 24 - 60 - 27 - 50)
  }, []);
  return (
    // display: "flex", flexDirection: "column",
    <div style={{ margin: '0 auto', marginTop: '24px', marginBottom: '60px', width: '343px', height: "calc(100vh - 216px)", overflow: "hidden", minHeight: '200px' }}>
      <div style={{ height: "16px", display: 'flex', justifyContent: 'space-between', fontSize: '14px', color: '#848BB0' }}>
        <div>我的客户记录</div>
        <div onClick={_ => { closeTip(true, _) }} style={{ position: 'relative' }}>佣金说明<img alt='' style={{ marginLeft: '4px', width: '13px', verticalAlign: 'top', marginTop: '2px' }} src={[require('@/assets/money_icon_doubt@3x.png')]} />
          {visible && <div style={{ zIndex: '3', whiteSpace: 'pre-line', borderRadius: '4px', width: '328px', position: 'absolute', top: '20px', left: '-250px', backgroundColor: '#4F577E', lineHeight: '20px', fontSize: '12px', color: '#fff' }}>
            <div style={{ position: 'absolute', top: '-13px', left: '280px', width: '0', height: '0', border: '8px solid transparent', borderBottom: '8px solid #4F577E', lineHeight: '20px' }}></div>
            <div style={{ margin: '16px 12px' }}>{tip}</div>
            <div onClick={_ => { closeTip(false, _) }} style={{ position: 'fixed', top: '0', left: '0', width: '100vw', height: '100vh', zIndex: '3' }}></div>
          </div>}
        </div>
      </div>
      <div style={{ height: "27px", margin: '16px 0', display: 'flex' }}>
        <div onClick={_ => { setChoiceFlag(true) }} style={{ height: (choiceFlag) ? '27px' : '25px', width: '183px', border: (choiceFlag) ? 'none' : '1px solid #DFE2F2', backgroundColor: (choiceFlag) ? '#242E5F' : '#fff', borderRadius: '4px 0px 0px 4px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: (choiceFlag) ? '#fff' : '#848BB0' }}>已成交({deal.order_num})</div>
        <div onClick={_ => { setChoiceFlag(false) }} style={{ height: (choiceFlag) ? '25px' : '27px', width: '183px', border: (choiceFlag) ? '1px solid #DFE2F2' : 'none', backgroundColor: (!choiceFlag) ? '#242E5F' : '#fff', borderRadius: '0px 4px 4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: (choiceFlag) ? '#848BB0' : '#fff' }}>未成交({noDeal.order_num})</div>
      </div>
      <PullToRefresh
        key={choiceFlag}
        // damping={60}
        distanceToRefresh={60}
        page_size={5}
        // ref={el => this.ptr = el}
        style={{
          height
        }}
        refreshing={refreshing}
        onRefresh={() => {
          setRefreshing(true);
          return getRecordList({ state: +choiceFlag, page: (choiceFlag ? deal : noDeal)["page"] }).then(resultList => {
            return resultList
          })
        }}
      >
        <div>
          {
            (choiceFlag ? deal : noDeal).list.map((val, index) =>
              <div key={index} className="record-item">
                <div className="record-order-num">
                  {val.type != null && <img className="record-order-icon" src={[require(`@/${recordType[val.type].icon}`)]} alt="" />}
                  {recordType[val.type].name}订单{val.order_id}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <div>{choiceFlag ? "已成功支付" : "已退款"}{(val.price !== null) && `￥${(val.price / 100).toFixed(2)}`}</div>
                    <div style={{ fontSize: '12px', color: '#848BB0', marginTop: '6px' }}>{val.payment_time}</div>
                  </div>
                  <div style={{ fontSize: '22px', color: '#FF0094', fontWeight: "bold" }}>
                    <span style={{ fontSize: "16px", marginRight: '2px' }}>¥</span>{val.commission.toFixed(2)}
                  </div>
                </div>
                {(choiceFlag ? (val.is_withdraw === 1) : val.canTi) &&
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', right: '0px', top: '0px', width: '53px', height: '20px', backgroundColor: '#9FA5C3', borderRadius: '0px 4px 0px 12px', fontSize: '11px', color: '#FFFFFF' }}>
                    <div>已提现</div>
                  </div>
                }
              </div>
            )
          }
        </div>
      </PullToRefresh>
    </div>
  )
}