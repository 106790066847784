import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {getPhoneDao} from "@/utils/dao.js";
import incomeService from '@/service/incomeService'
import './index.scss'
export default withRouter(function WithdrawRecord(params) {
    const [recordList, setRecordList] = useState([]);
    useEffect(_ => {
        incomeService.getCashOutRecord({
            params: { phone: getPhoneDao() },
            onSuccess: ({ data: { result } }) => {
                setRecordList(result)
            }
        })
    }, []);
    return (
        <>
            {recordList.length ? recordList.map((item, idx) => (
                <div key={idx} className="record">
                    <div>
                        <div className="record-title">提现到账户</div>
                        <div className="record-date">{item.time}</div>
                    </div>
                    <div className="record-amount"><span className="record-unit">￥</span>{item.price.toFixed(2)}</div>
                </div>
            )) : (
                    <div className="record-null">
                        <div className="record-null-wrap">
                            <img className="record-null-img" src={[require("@/assets/null_money.png")]} alt="" />
                            <div className="record-null-tip">当前还没有提现记录哦～</div>
                        </div>
                    </div>
                )
            }

        </>
    )
});
