import React from "react";
import { Toast } from "antd-mobile";
import { setTitle } from '@/utils/tools'
import {getPhoneDao} from "@/utils/dao.js";
import "./index.scss";
import Head from "./components/head";
// import Tip from "./components/tip";
import Step from "../components/step";
import ImgLoad from "./components/imgLoad";
import Share from "./components/share";
import UseStrategy from "../components/useStrategy";
import publicizeService from "@/service/publicizeService";
import shopService from "@/service/shopService";
import store from "@/store/store";
import action from "@/store/action";
export default class Publicize extends React.Component {
  state = {
    tabIndex: 0,
    showShare: false,
    isPaper: false,
    isTip: true,
    imgArr: [],
    postId: 0,
    inviteCode: ""
  };
  componentWillMount() {
    setTitle("宣传物料")
  }
  componentDidMount() {
    this.confirmIsReload();
    this.setState({
      phone: getPhoneDao()
    });
    this.getPreviewImgs();
    this.getUserInfo();
  }
  confirmIsReload() {
    if (!!window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      if (
        window["__wxjs_environment"] === "miniprogram" ||
        /microMessenger/i.test(window.navigator.userAgent)
      ) {
        if (!sessionStorage.getItem("lq-reload-flag")) {
          console.log(`lq-reload-flag执行`);
          sessionStorage.setItem("lq-reload-flag", true);
          window.location.reload(true);
        }
      }
    }
  }
  getUserInfo() {
    shopService.userInfo({
      params: { phone: getPhoneDao() },
      onSuccess: ({ data }) => {
        console.log(data);
        this.setState({
          inviteCode: data.invite_code
        });
      }
    });
  }
  getPreviewImgs() {
    publicizeService.previewImgs({
      onSuccess: ({ data }) => {
        this.setState({
          imgArr: data.result
        });
      }
    });
  }
  choiceTab(index) {
    console.log(index);
    if (window.navigator.userAgent.indexOf("Alipay") > -1) {
      this.setState({
        tabIndex: index,
        isPaper: index === 1 ? true : false
      });
    } else {
      this.setState({
        tabIndex: index,
        isPaper: index === 2 ? true : false
      });
    }
  }
  choiceImg(index) {
    //得到图片模版号
    let params = {
      phone: this.state.phone,
      image_id: index,
      type_id: this.state.tabIndex === 2 ? 1 : 0
    };
    if (window.navigator.userAgent.indexOf("Alipay") > -1) {
      params.type_id = this.state.tabIndex === 1 ? 1 : 0;
      let alipath = window.localStorage.getItem("lq-pocket-aliPath");
      if (alipath) {
        params.url_param = alipath;
      }
    }
    publicizeService.shareImg({
      params: params,
      onSuccess: ({ data }) => {
        // let url = data.url.substr(0,data.url.indexOf('.jpg?')+4)
        this.setState(
          {
            isTip: false,
            showShare: true,
            imgUrl: data.url,
            postId: index
          },
          () => { }
        );
      }
    });
  }
  close(bol) {
    this.setState({
      showShare: bol
    });
  }
  share() {
    Toast.info("分享文案已复制", 2);
    setTimeout(_ => {
      if (window.navigator.userAgent.indexOf("Alipay") > -1) {
        window.mixSDK.shareToWx(
          this.state.inviteCode,
          this.state.imgUrl,
          "最美证件照，智能美颜、一键换正装，10秒搞定你所有的证件照"
        );
      } else {
        window.mixSDK.shareToWx(
          this.state.tabIndex,
          this.state.imgUrl,
          "最美证件照，智能美颜、一键换正装，10秒搞定你所有的证件照"
        );
      }
    }, 1000);
  }
  goPay() {
    //将图片放入缓存
    store.dispatch(action.setPostUrl(this.state.imgUrl));
    this.props.history.push(`/order/pay?postId=${this.state.postId}`);
  }
  render() {
    return (
      <div className="publicize">
        <Head choiceTab={this.choiceTab.bind(this)} />
        <UseStrategy />
        <Step />
        {/* : <Tip /> */}
        <ImgLoad
          imgArr={this.state.imgArr}
          choiceImg={index => this.choiceImg(index)}
          isTip={this.state.isTip}
          tabIndex={this.state.tabIndex}
        />
        <Share
          goPay={_ => this.goPay()}
          imgUrl={this.state.imgUrl}
          share={_ => this.share()}
          tabIndex={this.state.tabIndex}
          isPaper={this.state.isPaper}
          isShow={this.state.showShare}
          close={bol => this.close(bol)}
        />

      </div>
    );
  }
}
