import React from 'react'
import './index.scss'
import orderService from '@/service/orderService'
const qs = require('query-string');
export default class Detail extends React.Component{
  state={
    data:{}
  }
  componentDidMount(){
    let {orderId} = qs.parse(this.props.location.search)
    orderService.getOrderDetail({
      params:{order_id:orderId},
      onSuccess:({data})=>{
        console.log(data)
        this.setState({
          data
        })
      }
    })
  }
  render(){
    return(
      <div className="detail">
        <div className="detail-bank"></div>
        <div className="detail-div">
          <div className="row">
            <div className="label">订单号</div>
            <div className="value">{this.state.data.order_id}</div>
          </div>
          <div className="row">
            <div className="label">下单时间</div>
            <div className="value">{this.state.data.payment_time}</div>
          </div>
          <div className="row">
            <div className="label">购买数量</div>
            <div className="value">{this.state.data.number}张</div>
          </div>
          <div className="row">
            <div className="label">实付金额(含设计+快递+纸质海报)</div>
            <div className="value">¥<span>{this.state.data.price}</span></div>
          </div>
        </div>
        <div className="tab">收货人信息</div>
        <div className="detail-div">
          <div className="row">
            <div className="label">收货人姓名</div>
            <div className="value">{this.state.data.consignee_name}</div>
          </div>
          <div className="row">
            <div className="label">联系方式</div>
            <div className="value">{this.state.data.consignee_phone}</div>
          </div>
          <div className="row">
            <div className="label">收货地址</div>
            <div className="value">{this.state.data.consignee_address}</div>
          </div>
        </div>
        <div className="tab">物流信息(每日16:00前的订单当天发货，16:00以后的订单次日发货，周末不发货。）</div>
          <div className="detail-div">
          <div className="row">
            <div className="label">订单状态</div>
            <div className="value">{this.state.data.express_state}</div>
          </div>
          <div className="row">
            <div className="label">快递公司</div>
            <div className="value">{this.state.data.express_company}</div>
          </div>
          <div className="row">
            <div className="label">快递单号</div>
            <div className="value">{this.state.data.express_number}</div>
          </div>
        </div>
      </div>
    )
  }
}